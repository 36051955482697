<script setup>
import {hasValue} from "@/utils/validationUtils.js";
import {getNextOrder} from "@/utils/orderUtils.js";

const { api } = useFeathers()
const route = useRoute()

const store = useObjectClassificationStore()
const objectClassificationCategoryStore = useObjectClassificationCategoryStore()

const {currentItem, validationErrors, writeLoading} = storeToRefs(store)
const { items: objectClassificationCategoryItems  } = storeToRefs(objectClassificationCategoryStore)

let emit = defineEmits();

watch(() => route.query.category, (newValue) => {
  formData.value.category = newValue
})

const formData = ref({
  name: '',
  description: '',
  category: route.query?.category || null,
})
const isValid = ref(true)
const formElement = ref(null)

const searchCategory = ref('')

async function saveObject() {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let item
  if(formData.value._id) {
    item = await store.update(formData.value)
  } else {
    const orderArray = objectClassificationCategoryItems.value
      .find(item => item._id === formData.value.category)?.children
      ?.map(objectClassification => objectClassification.order) || [];
    item = await store.create({
      ...formData.value,
      order: getNextOrder(orderArray),
    })
  }

  if(item) {
    store.setCurrentItem(item)
    emit('saved', item)
    store.toggleCreateUpdateDialog(false)
  }
}

watchEffect(async () => {
  if(currentItem.value?._id){
    const clone = structuredClone(toRaw(currentItem.value))
    formData.value = {
      _id: clone._id,
      name: clone.name,
      description: clone.description,
      category: clone.category._id,
    }
  } else {
    formData.value = {
      category: route.query?.category || null,
    }
  }
})
</script>

<template>
  <h1 class="font-weight-thin">
    <template v-if="!currentItem || !currentItem._id">New object classification</template>
    <template v-else>{{ currentItem.name }}</template>
  </h1>

  <v-form ref="formElement" @submit.prevent="saveObject" v-model="isValid">
    <v-container fluid class="pa-0 ma-0">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
              v-model="formData.name"
              :rules="[
                  v => hasValue(v)
              ]"
              max-errors="999"
              :error-messages="validationErrors.name?.$errors"
              label="Name"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
              v-model="formData.category"
              v-model:search="searchCategory"
              :rules="[
                  v => hasValue(v)
              ]"
              max-errors="999"
              :error-messages="validationErrors.category?.$errors"
              item-title="name"
              item-value="_id"
              :items="objectClassificationCategoryItems"
              clearable
              hide-no-data
              label="Category"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
              v-model="formData.description"
              :rules="[
                  v => hasValue(v)
              ]"
              max-errors="999"
              :error-messages="validationErrors.description?.$errors"
              label="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
              @click="saveObject"
              :disabled="isValid === false"
              :loading="writeLoading"
              color="primary"
          >Save</v-btn>

          <v-btn
              class="ms-3"
              @click="emit('cancel')"
              color="error"
          >Cancel</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
