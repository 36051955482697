<script setup>
import ToolBar from './ToolBar.vue';
import HeaderMenu from "./HeaderMenu.vue";
import HeaderLogo from "./HeaderLogo.vue";

const uiStore = useUiStore()
</script>


<template>
  <v-app-bar
      fixed
      app
      color="white"
      dark
      class="pa-0"
      height="90"
      extension-height="40"
  >
    <v-app-bar-nav-icon variant="text" @click.stop="uiStore.toggleSideBar"></v-app-bar-nav-icon>
    <HeaderLogo/>
      <v-spacer></v-spacer>
    <HeaderMenu/>
    <template v-slot:extension>
      <ToolBar/>
    </template>
  </v-app-bar>
</template>

<style scoped>
:deep(.v-toolbar__extension) {
  padding: 0 !important;
}

.v-toolbar__extension {
  padding: 0 !important;
}
</style>
