<script setup>
import {hasValue} from "../../utils/validationUtils.js";

const authorizationManagementRolesStore = inject('authorizationManagementRolesStore')

const { api } = useFeathers()

let emit = defineEmits();

const projectMemberStore = useProjectMembersStore()
const userStore = useUserStore()

const { currentItem, createUpdateDialog, 'items': projectMembers, writeLoading, validationErrors } = storeToRefs(projectMemberStore)
const { items } = storeToRefs(userStore)
const { 'items': authManagementRoles } = storeToRefs(authorizationManagementRolesStore)

const loadingAvailableUsers = ref(false)
const searchUsers = ref(null)
const searchRole = ref(null)

const projectMemberFormData = ref({})
const isValid = ref(true)
const formElement = ref(null)

watch(currentItem, (newValue) => {
  projectMemberFormData.value = newValue ? structuredClone(toRaw(newValue)) : {};
}, { deep: true })

async function saveProjectMember () {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let item
  if (currentItem.value?._id) {
    item = await projectMemberStore.update(projectMemberFormData.value)
  } else {
    item = await projectMemberStore.create(projectMemberFormData.value)
  }

  if(item) {
    emit('saved', item)
    projectMemberStore.toggleCreateUpdateDialog(false)
  }
}

const availableRoles = ref([])
const availableUsers = ref([])
onMounted(async () => {
  await authorizationManagementRolesStore.fetchItems({
    authSource: 'project-members'
  })
})

watch(() => createUpdateDialog.value, async () => {
  if(createUpdateDialog.value === true) {
    loadingAvailableUsers.value = true
    // Fetch all users from the API
    let availableUserList = await userStore.fetchItems()
    // Build an ID-list of members that are already in the project
    let currentProjectUsers = projectMembers.value.map(x => x.user._id)
    // Filter out any users that are already a project member
    if(!currentItem.value._id) {
      availableUserList = availableUserList.filter(x => !currentProjectUsers.includes(x._id))
    }
    // Convert it to a searchable list with first name, last name and email address
    availableUsers.value = availableUserList.map(user => ({
      title: user.fullName + ' (' + user.email + ')',
      value: user._id
    }))
    loadingAvailableUsers.value = false
  }
})
</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="700px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="currentItem?._id">Update member {{ currentItem.user.fullName }}</span>
        <span v-else>Add new member</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="saveProjectMember" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    :disabled="currentItem._id"
                    v-model="projectMemberFormData.user"
                    v-model:search="searchUsers"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.user?.$errors"
                    :loading="loadingAvailableUsers"
                    :items="availableUsers"
                    label="Select a user"
                    no-data-text="No user found matching query"
                    item-value="value"
                    item-title="title"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="projectMemberFormData.role"
                    v-model:search="searchRole"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.role?.$errors"
                    :items="authManagementRoles"
                    item-value="_id"
                    item-title="name"
                    :menu-props="{ maxHeight: '400' }"
                    label="Select a role"
                    no-data-text="No role found matching query"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="saveProjectMember"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>