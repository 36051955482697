<script setup>
import {
    getMaxDateValue,
    getMaxLength,
    getMaxValue,
    getMinDateValue,
    getMinLength,
    getMinValue,
    getStepSize,
    isMultipleOf
} from "@/utils/formUtils.js";
import isNil from 'lodash/isNil'
import {DateTime} from "luxon";

const props = defineProps(['modelValue', 'field'])
const emit = defineEmits(['update:modelValue'])

const projectStore = useProjectStore()
const objectStore = useObjectStore()

const {currentItem: currentProject} = storeToRefs(projectStore)
const {validationErrors} = storeToRefs(objectStore)

function emitUpdate(value, field){
    if(validationErrors.value && validationErrors.value.data){
        delete validationErrors.value.data[field._id]
    }
    emit('update:modelValue', value)
}

const generateRules = computed(() => {
    const validations = []

    console.log('GENERATE RULES')
    if(props.field.required){
      if(['string', 'choice', 'multiplechoice', 'date-time', 'date'].includes(props.field.dataType)){
          console.log('GENERATE THIS RULE')
          validations.push(value => {
              if(value && value.length > 0){
                  return true
              }
              return 'Field input is required.'
          })
      }
        if(['number','integer'].includes(props.field.dataType)){
            validations.push(value => {
                if(value !== null && typeof value !== "undefined" && !isNaN(value)){
                    return true
                }
                return 'Field input is required.'
            })
        }
      else if(['boolean'].includes(props.field.dataType)){
          validations.push(value => {
              if(value === true || value === false){
                  return true
              }
              return 'Field input is required.'
          })
      }
    }

    if(['string'].includes(props.field.dataType)) {
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || !value){
                return true
            }
            const minLength = getMinLength(props.field)
            if (!minLength || value?.length >= minLength || (value?.length === 0 && !props.field.required)) {
                return true
            }
            return 'Field value should be at least ' + minLength + ' characters long.'
        })
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || !value){
                return true
            }

            const maxLength = getMaxLength(props.field)
            if (!maxLength || value?.length <= maxLength || (value?.length === 0 && !props.field.required)) {
                return true
            }
            return 'Field value should be at most ' + maxLength + ' characters long.'
        })
    }

    if(['number', 'integer'].includes(props.field.dataType)) {
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || value === null){
                return true
            }

            const minValue = getMinValue(props.field)
            if (isNil(minValue) || value >= minValue) {
                return true
            }
            return 'Field value should have a value of at least ' + minValue + '.'
        })
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || value === null){
                return true
            }

            const maxValue = getMaxValue(props.field)
            if (isNil(maxValue) || value <= maxValue) {
                return true
            }
            return 'Field value should have a value of at most ' + maxValue + '.'
        })

        if(props.field.dataType === 'number') {
            validations.push(value => {
                // Do not run this validator if there is no value to check, it should be checked by the required rule
                if (typeof value === 'undefined' || value === null) {
                    return true
                }
                const numberValue = typeof value === "number" ? value : Number(value)
                if (!props.field.precision || isMultipleOf(numberValue, props.field.precision)) {
                    return true
                }
                return 'Field value should be a multiple of ' + props.field.precision + '.'
            })
        }
    }

    if(['date'].includes(props.field.dataType)) {
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || !value){
                return true
            }

            const minDate = getMinDateValue(props.field, currentProject.value.timezone)
            if (!minDate || value >= minDate) {
                return true
            }
            return 'Field value should have a value of at least ' + minDate + '.'
        })
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || !value){
                return true
            }

            const maxDate = getMaxDateValue(props.field, currentProject.value.timezone)
            if (!maxDate || value <= maxDate) {
                return true
            }
            return 'Field value should have a value of at most ' + maxDate + '.'
        })
    }

    if(['date-time'].includes(props.field.dataType)) {
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || !value){
                return true
            }

            const minDateTime = getMinDateValue(props.field, currentProject.value.timezone)
            const valueAsDate = DateTime.fromISO(value, { zone: currentProject.value.timezone }).toISO({
                includeOffset: true
            })
            if (!minDateTime || valueAsDate >= minDateTime) {
                return true
            }
            return 'Field value should have a value of at least ' + minDateTime + '.'
        })
        validations.push(value => {
            // Do not run this validator if there is no value to check, it should be checked by the required rule
            if(typeof value === 'undefined' || !value){
                return true
            }

            const maxDateTime = getMinDateValue(props.field, currentProject.value.timezone)
            const valueAsDate = DateTime.fromISO(value, { zone: currentProject.value.timezone }).toISO({
                includeOffset: true
            })
            if (!maxDateTime || valueAsDate >= maxDateTime) {
                return true
            }
            return 'Field value should have a value of at most ' + maxDateTime + '.'
        })
    }

    return validations
})
</script>

<template>
  <v-tooltip
      :text="field.name"
      location="top"
      :scrim="false"
      no-click-animation
  >
    <template v-slot:activator="{ props }">
      <div v-bind="props">
        <v-text-field
            v-if="['string'].includes(field.dataType)"
            @update:modelValue="emitUpdate($event, field)"
            :model-value="modelValue"
            :label="field.name"
            :hint="field.helpText"
            :minlength="getMinLength(field)"
            :maxlength="getMaxLength(field)"
            :required="field.required"
            :rules="generateRules"
            max-errors="999"
            :error-messages="validationErrors.data && validationErrors.data[field._id] ? validationErrors.data[field._id].$errors : []"
            type="text"
            persistent-hint
            clearable
            persistent-clear
            persistent-placeholder
            class="mr-md-3 w-100"
        >
          <template v-slot:prepend>
            <v-tooltip :text="field.description" v-if="field.description">
              <template v-slot:activator="{ props }">
                <v-icon class="mb-1" v-bind="props">mdi-information</v-icon>
              </template>
            </v-tooltip>
            <div v-else class="d-block ps-6"></div>
          </template>
        </v-text-field>
        <v-text-field
            v-if="['number', 'integer'].includes(field.dataType)"
            @update:modelValue="emitUpdate($event ?? Number($event), field)"
            :model-value="modelValue"
            :label="field.name"
            :step="getStepSize(field)"
            :min="getMinValue(field)"
            :max="getMaxValue(field)"
            :hint="field.helpText"
            :required="field.required"
            :rules="generateRules"
            max-errors="999"
            :error-messages="validationErrors.data && validationErrors.data[field._id] ? validationErrors.data[field._id].$errors : []"
            type="number"
            persistent-hint
            clearable
            persistent-clear
            persistent-placeholder
            class="mr-md-3 w-100"
        >
          <template v-slot:prepend>
            <v-tooltip :text="field.description" v-if="field.description">
              <template v-slot:activator="{ props }">
                <v-icon class="mb-1" v-bind="props">mdi-information</v-icon>
              </template>
            </v-tooltip>
            <div v-else class="d-block ps-6"></div>
          </template>
        </v-text-field>
        <v-text-field
            v-if="['date', 'date-time'].includes(field.dataType)"
            @update:modelValue="emitUpdate($event, field)"
            :model-value="modelValue"
            :label="field.name + '(' + currentProject.timezone + ')'"
            :hint="field.helpText"
            :required="field.required"
            :type="field.dataType === 'date' ? 'date' : 'datetime-local'"
            :min="getMinDateValue(field, currentProject.timezone)"
            :max="getMaxDateValue(field, currentProject.timezone)"
            :rules="generateRules"
            max-errors="999"
            :error-messages="validationErrors.data && validationErrors.data[field._id] ? validationErrors.data[field._id].$errors : []"
            persistent-hint
            clearable
            persistent-clear
            persistent-placeholder
            class="mr-md-3 w-100">

          <template v-slot:prepend>
            <v-tooltip :text="field.description" v-if="field.description">
              <template v-slot:activator="{ props }">
                <v-icon class="mb-1" v-bind="props">mdi-information</v-icon>
              </template>
            </v-tooltip>
            <div v-else class="d-block ps-6"></div>
          </template>
        </v-text-field>
        <template v-if="['boolean'].includes(field.dataType)">
          <v-radio-group
              class="radio-group"
              :model-value="modelValue"
              @update:modelValue="emitUpdate($event, field)"
              :label="field.name"
              :hint="field.helpText"
              :required="field.required"
              :rules="generateRules"
              max-errors="999"
              :error-messages="validationErrors.data && validationErrors.data[field._id] ? validationErrors.data[field._id].$errors : []"
              inline>
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <template v-slot:prepend>
              <v-tooltip :text="field.description" v-if="field.description">
                <template v-slot:activator="{ props }">
                  <v-icon class="mb-1" v-bind="props">mdi-information</v-icon>
                </template>
              </v-tooltip>
              <div v-else class="d-block ps-6"></div>
            </template>
          </v-radio-group>
          <v-messages class="ps-14" :active="true" :messages="[field.helpText]"></v-messages>
        </template>
        <template v-if="['choice', 'multiplechoice'].includes(field.dataType)">
          <v-select
              :model-value="modelValue"
              @update:modelValue="emitUpdate($event, field)"
              :items="field.choices"
              :label="field.name"
              :hint="field.helpText"
              :required="field.required"
              :multiple="field.dataType === 'multiplechoice'"
              :rules="generateRules"
              max-errors="999"
              :error-messages="validationErrors.data && validationErrors.data[field._id] ? validationErrors.data[field._id].$errors : []"
              item-value="value"
              item-title="displayValue"
              inline>
            <template v-slot:prepend>
              <v-tooltip :text="field.description" v-if="field.description">
                <template v-slot:activator="{ props }">
                  <v-icon class="mb-1" v-bind="props">mdi-information</v-icon>
                </template>
              </v-tooltip>
              <div v-else class="d-block ps-6"></div>
            </template>
          </v-select>
          <v-messages class="ps-14" :active="true" :messages="[field.helpText]"></v-messages>
        </template>
      </div>
    </template>
  </v-tooltip>
</template>

<style scoped>
:deep(.v-input__details) {
  white-space: pre-wrap;
}

:deep(.v-field-label) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.radio-group :deep(.v-label:first-child)  {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
