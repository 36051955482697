<script setup>
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import ChangeItemOrder from "@/components/utils/ChangeItemOrder.vue";
import {getUpperOrder, getLowerOrder} from "@/utils/orderUtils.js";

const { api } = useFeathers()
const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()
const objectClassificationFieldStore = useObjectClassificationFieldStore()

const {items, deleteItem} = storeToRefs(objectClassificationFieldStore)

const props = defineProps({
  objectClassification: {
    type: Object,
    required: true
  }
})

// Ref to track searching in user data table
const search = ref(null)
const headers = [
  { title: 'Name', align: 'start', key: 'name' },
  { title: 'Data type', align: 'start', key: 'dataType' },
  { title: 'Help text', align: 'start', key: 'helpText' },
  { title: 'Required', align: 'start', key: 'required' },
  { title: 'Actions', align: 'end', key: 'actions' },
]

watch(() => props.objectClassification, () => {
  refreshObjectClassificationFields()
})

const itemsDisplay = computed(() => getSortedByOrder(items.value))

function getSortedByOrder(items) {
  return [...items].sort((a, b) => a.order - b.order)
}

async function moveItem({currentItem, orderGetter, store, items}) {
  if (store.writeLoading) {
    snackbarStore.setMessage('Still waiting for server to process the request, please try again later', 'info', 5000)
    return
  }
  const order = orderGetter(items.map(item => item.order), currentItem.order)
  if (order === null || typeof order === 'undefined') {
    snackbarStore.setMessage('Could not calculate new order of the item', 'error', 5000)
  }
  await store.update({ _id: currentItem._id, order })
}

async function moveFieldUp(field) {
  await moveItem({
    currentItem: field,
    orderGetter: getUpperOrder,
    store: objectClassificationFieldStore,
    items: itemsDisplay.value,
  })
}

async function moveFieldDown(field) {
  await moveItem({
    currentItem: field,
    orderGetter: getLowerOrder,
    store: objectClassificationFieldStore,
    items: itemsDisplay.value,
  })
}

async function refreshObjectClassificationFields() {
  let query = {}
  if(props.objectClassification?._id){
    query.objectClassification = props.objectClassification._id
  }

  await objectClassificationFieldStore.fetchItems(query)
}

onMounted(async () => {
  await refreshObjectClassificationFields()
})

async function onObjectClassificationFieldSaved(){
  snackbarStore.setMessage('Object classification field was saved successfully', 'success', 5000)
}

async function onObjectClassificationFieldDeleted(){
  snackbarStore.setMessage('Object classification field was removed successfully', 'success', 5000)
}
</script>

<template>
  <v-text-field
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Search fields"
      label="Search fields"
      single-line
      hide-details
      class="mb-5"
  ></v-text-field>

  <v-data-table
      v-if="itemsDisplay.length > 0"
      items-per-page="50"
      :headers="headers"
      :items="itemsDisplay"
      item-value="email"
      density="compact"
      :search="search"
  >
    <!--      Both below attributes of the v-data-table are not working at the time of writing -->
    <!--      no-data-text="There are no results to be displayed." -->
    <!--      no-data="There are no results to be displayed." -->
    <template #no-data>
      <tr class="v-data-table__tr">
        <td class="v-data-table__td" colspan="7">
          There are no results to be displayed.
        </td>
      </tr>
    </template>

    <template v-slot:item.required="{ item }">
      <span v-if="item.raw.required">Yes</span>
      <span v-else>No</span>
    </template>

    <template v-slot:item.actions="{ item }">

      <div class="d-flex flex-row justify-end align-center">
              <v-icon
          size="small"
          @click="objectClassificationFieldStore.toggleCreateUpdateDialog(item.raw)"
          v-if="authStore.hasObjectPermission(item.raw, ['patch_all'])"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          class="ms-1"
          size="small"
          @click="objectClassificationFieldStore.toggleDeleteDialog(item.raw)"
          v-if="authStore.hasObjectPermission(item.raw, ['remove_all'])"
      >
        mdi-delete
      </v-icon>
      <change-item-order
        @move-up="moveFieldUp(toRaw(item.raw))"
        @move-down="moveFieldDown(toRaw(item.raw))"
        color=""
      />
      </div>
    </template>
  </v-data-table>

  <delete-dialog :store="objectClassificationFieldStore" :name="deleteItem?.name" @deleted="onObjectClassificationFieldDeleted"></delete-dialog>
</template>
