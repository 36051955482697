import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('project-access-tokens', api, snackbarStore);


export const useProjectTokenStore = defineStore('projectToken', {
    state: () => ({
        ...baseStore.state,
    }),
    actions: {
        ...baseStore.actions,
    },
})
