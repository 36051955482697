export const useObjectTypeStore = defineStore('objectType', {
    state: () => ({
        objectTypes: []
    }),
    actions: {
        setObjectTypes (objectTypes) {
            this.objectTypes = objectTypes
        },
        getObjectTypes () {
            return this.objectTypes
        }
    },
})