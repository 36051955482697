<script setup>
const store = useProjectObjectClassificationStore()

const {project} = storeToRefs(useProjectStore())
const {items: objectClassificationCategoryItems} = storeToRefs(useObjectClassificationCategoryStore())

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  multiple: {
    type: Boolean,
    required: false,
    default: true
  },
  selected: {
    required: true
  }
})

let emit = defineEmits(['update']);


const selectedItem = computed({
  get() {
    return props.selected;
  },
  set(value) {
    emit('update', value)
  }
});


const objectTypesGrouped = computed(() => {
  let tree = []
  for (const category of objectClassificationCategoryItems.value) {
    const children = props.items.filter(item => {
        return item.category?._id === category._id
    })
    if (children.length) {
      tree.push({
        ...category,
        disabled: true,
      })
    }
    tree.push(...children)
  }
  return tree
})

function searchObjectType(itemText, queryText, item) {
  return item.title.toLowerCase().includes(queryText.toLowerCase())
}

</script>
<template>
  <v-autocomplete
      v-model="selectedItem"
      :items="objectTypesGrouped"
      item-title="name"
      no-filter
      color="primary"
      item-disabled="disabled"
      header
      :item-props="true"
      item-value="_id"
      :filter-keys="['name', '_id', 'type']"
      :custom-filter="searchObjectType"
      filled
      :multiple="props.multiple"
      label="Object classification"
  >
    <template #item="{ props, index }">
      <template v-if="props.disabled">
        <v-list-item density="compact" style="opacity: 1;" v-bind="props">
          <template #title>
            <div>
              <small><strong>{{ props.title }}</strong></small>
            </div>
          </template>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <v-list-item v-else v-bind="props" :disabled="!objectTypesGrouped[index].fields?.length">
        <template #title>
          <div class="pl-4">
            {{ props.title }}
            <small class="float-right">
            {{ !objectTypesGrouped[index].fields?.length ? "No fields available" : ""  }}
            </small>
          </div>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>

</template>
