import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('project-object-classifications', api, snackbarStore);


export const useProjectObjectClassificationStore = defineStore('projectObjectClassification', {
    state: () => ({
        ...baseStore.state,
    }),
    actions: {
        ...baseStore.actions,
        defineObjects() {
            const projectStore = useProjectStore();
            const {currentItem} = storeToRefs(projectStore)
            if(this.selectedItems) {
                return this.selectedItems.map(item => {
                    return {project: currentItem.value._id, classification: item}
                })
            } else {
                return []
            }
        }
    },
})
