import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('object-classifications', api, snackbarStore);


export const useObjectClassificationStore = defineStore('objectClassification', {
    state: () => ({
        ...baseStore.state,
    }),
    actions: {
        ...baseStore.actions,
    },
    getters: {
        ...baseStore.getters,
        selectableProjectObjectClassifications(state) {
            const projectClassificationStore = useProjectObjectClassificationStore()
            const projectStore = useProjectStore()
            const {items: projectClassificationItems} = storeToRefs(projectClassificationStore)
            const {currentItem: currentProject} = storeToRefs(projectStore)

            return state.items.filter(item => {
                const isNotSelected = !projectClassificationItems.value.map(i => i.classification && i.classification._id).includes(item._id)
                const isProjectsItem = item.project === null || item.project === currentProject.value._id
                return isProjectsItem && isNotSelected
            })
        },
        getByCategoryId: (state) => ({categoryId, projectId = null} = {}) => {
            return state.items.filter(item => item.category?._id === categoryId && item.project === projectId)
        },
        cloneableItems: (state) => {
            return state.items.filter(item => !item.project)
        }
    },

})
