<script setup>
import ProjectDialog from "@/components/projects/ProjectDialog.vue";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";

const {api} = useFeathers()
const authStore = useAuthStore()
const store = useProjectStore()
const route = useRoute()
const router = useRouter()

const {currentItem} = storeToRefs(store)

const snackbarStore = useSnackbarStore()

async function onObjectDeleted() {
  snackbarStore.setMessage('Project was deleted successfully', 'success', 5000)

  await router.push({
    name: 'project-index'
  })
}

async function onProjectSaved(project) {
  snackbarStore.setMessage('Project ' + project.name + ' was saved successfully.', 'success', 5000)
}
</script>

<template>
  <h1 class="font-weight-thin">
    {{ currentItem.name }}
    <v-icon v-if="authStore.hasObjectPermission(currentItem, ['patch_all', 'patch_assigned'])" size="23" class="mb-1" color="primary" @click="store.toggleCreateUpdateDialog(currentItem)">
      mdi-pencil-circle
    </v-icon>

    <v-btn color="error"
           v-if="authStore.hasObjectPermission(currentItem, ['remove_all', 'remove_assigned'])"
           @click="store.toggleDeleteDialog(currentItem)"
           prepend-icon="mdi-delete"
           class="float-right">
      Delete project
    </v-btn>
  </h1>
  <span class="text-subtitle-2">{{ currentItem.workOrder }}</span>

  <v-table class="w-auto max-width-600 mt-3">
    <tr>
      <th class="text-left">Country</th>
      <td>{{ currentItem.country || '-' }}</td>
    </tr>
    <tr>
      <th class="text-left">Client</th>
      <td>{{ currentItem.client || '-' }}</td>
    </tr>
    <tr>
      <th class="text-left">Main contractor</th>
      <td>{{ currentItem.mainContractor || '-' }}</td>
    </tr>
    <tr>
      <th class="text-left">Year start</th>
      <td>{{ currentItem.startYear || '-' }}</td>
    </tr>
    <tr>
      <th class="text-left">Geolocation</th>
      <td>{{ currentItem.geoLocation?.x || '-' }}, {{ currentItem.geoLocation?.y || '-' }}</td>
    </tr>
  </v-table>

  <p class="mt-5">
    {{ currentItem.description || 'No project description available yet.' }}
  </p>

  <project-dialog @saved="onProjectSaved"></project-dialog>
  <delete-dialog :store="store" message="Are you sure you want to delete project <strong>{name}</strong>?" :name="currentItem.name" @deleted="onObjectDeleted"></delete-dialog>
</template>