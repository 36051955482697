import { useAuth } from 'feathers-pinia'

export const useAuthStore = defineStore('auth', () => {
    const { api } = useFeathers()
    const authResponse = ref(null)

    const auth = useAuth({
        api,
        onSuccess: async(result) => {
            authResponse.value = result
        },
        onInitSuccess: async(result) => {
            authResponse.value = result
        }
    })

    function isGlobalAdmin(){
        const grants = authResponse.value?.user?.role?.grants
        if(!grants){
            return false
        }

        let usersGrant = grants.find(grant => grant.service === 'users')
        if(usersGrant && usersGrant.permissions.includes('global_admin')){
            return true
        }
    }

    function hasServicePermission(service, allowed=[]){
        // Always allow global_admin to anything, if present at the users service
        if(isGlobalAdmin()){
            return true
        }

        const servicePermissionsList = authResponse.value?.user?.servicePermissions
        if(!servicePermissionsList || !servicePermissionsList[service]){
            return false
        }


        if(typeof allowed === 'string'){
            allowed = [allowed]
        }

        return servicePermissionsList[service].some(permission => allowed.includes(permission))
    }

    function hasObjectPermission(object, allowed=[]){
        if(isGlobalAdmin()){
            return true
        }

        if(!object?.objectPermissions){
            return false
        }

        return object.objectPermissions.some(permission => allowed.includes(permission))
    }

    return {
        hasServicePermission,
        hasObjectPermission,
        authResponse,
        ...auth,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}