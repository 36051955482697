<script setup>
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import ChangeItemOrder from "@/components/utils/ChangeItemOrder.vue";
import RowWithActions from "@/components/utils/RowWithActions.vue";
import {getLowerOrder, getUpperOrder} from "@/utils/orderUtils.js";

const {api} = useFeathers()
const router = useRouter()
const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()

const objectClassificationCategoryStore = useObjectClassificationCategoryStore()
const objectClassificationStore = useObjectClassificationStore()

const props = defineProps({
  projectId: {type: String, default: null},
})

const {items, fetchLoading, 'deleteItem': deleteItemObjectClassificationCategory } = storeToRefs(objectClassificationCategoryStore)
const {'deleteItem': deleteItemObjectClassification } = storeToRefs(objectClassificationStore)

const categoriesDisplay = computed(() => getSortedByOrder(items.value))

async function moveItem({currentItem, orderGetter, store, items}) {
  if (store.writeLoading) {
    snackbarStore.setMessage('Still waiting for server to process the request, please try again later', 'info', 5000)
    return
  }
  const order = orderGetter(items.map(item => item.order), currentItem.order)
  if (order === null || typeof order === 'undefined') {
    snackbarStore.setMessage('Could not calculate new order of the item', 'error', 5000)
  }
  await store.update({ _id: currentItem._id, order })
}

function getSortedByOrder(items) {
  return [...items].sort((a, b) => a.order - b.order)
}

async function moveCategoryUp(category) {
  await moveItem({
    currentItem: category,
    orderGetter: getUpperOrder,
    store: objectClassificationCategoryStore,
    items: categoriesDisplay.value,
  })
}

async function moveCategoryDown(category) {
  await moveItem({
    currentItem: category,
    orderGetter: getLowerOrder,
    store: objectClassificationCategoryStore,
    items: categoriesDisplay.value
  })
}

async function moveClassificationUp(classification, classifications) {
  await moveItem({
    currentItem: classification,
    orderGetter: getUpperOrder,
    store: objectClassificationStore,
    items: classifications,
  })
}

async function moveClassificationDown(classification, classifications) {
  await moveItem({
    currentItem: classification,
    orderGetter: getLowerOrder,
    store: objectClassificationStore,
    items: classifications,
  })
}

onMounted(async () => {
  await objectClassificationStore.fetchItems()
  await objectClassificationCategoryStore.fetchItems()
})

function onDeletedCategory(objectClassificationCategory){
  snackbarStore.setMessage('Category deleted successfully', 'success', 5000)
}

async function onDeletedClassification(objectClassification){
  await objectClassificationCategoryStore.fetchItems()
  snackbarStore.setMessage('Classification deleted successfully', 'success', 5000)
}

async function addObjectClassificationCategory(category) {
  objectClassificationStore.setCurrentItem({})
  await router.push({ name: 'object-classification-form', query: {
    category: category._id
  }})
}

function navigateToObjectClassificationDetail(objectClassificationId) {
  if (props.projectId) {
    router.push({
      name: 'project-custom-object-classifications',
      params: { id: props.projectId, classificationId: objectClassificationId},
    })
    return
  }
  router.push({ name: 'object-classification-details', params: { id: objectClassificationId }})
}

function getDisplayObjectClassificationByCategoryId(categoryId) {
  return getSortedByOrder(objectClassificationStore.getByCategoryId({categoryId, projectId: props.projectId}))
}
</script>
<template>
  <template v-if="fetchLoading">
    <v-row>
      <v-col cols="12">
        Loading...
      </v-col>
    </v-row>
  </template>
  <template v-else v-for="(category, categoryIndex) in categoriesDisplay" :key="category._id">
    <v-row>
      <v-col cols="12" class="font-weight-bold border-b">
        <row-with-actions>
          <template v-slot:left>
            {{ category.name }}
          </template>
          <template v-slot:right v-if="!props.projectId">
            <v-icon
              size="23"
              color="primary"
              v-if="authStore.hasServicePermission('object-classification-categories', ['patch_all'])"
              @click.stop="objectClassificationCategoryStore.toggleCreateUpdateDialog(category)"
              icon="mdi-pencil-circle"
            />
            <v-icon
              size="23"
              class="ml-1"
              color="primary"
              v-if="authStore.hasServicePermission('object-classifications', ['create_all'])"
              @click.stop="addObjectClassificationCategory(category)"
              icon="mdi-plus-circle"
            />
            <v-icon
              size="23"
              class="ml-1"
              color="primary"
              v-if="authStore.hasServicePermission('object-classification-categories', ['remove_all'])"
              @click.stop="objectClassificationCategoryStore.toggleDeleteDialog(category)"
              icon="mdi-delete"
            />
            <change-item-order
              v-if="authStore.hasServicePermission('object-classification-categories', ['patch_all'])"
              class="ml-3"
              :disabled-up="categoryIndex === 0"
              :disabled-down="categoryIndex === categoriesDisplay.length - 1"
              @move-up="moveCategoryUp(toRaw(category))"
              @move-down="moveCategoryDown(toRaw(category))"
            />
          </template>
        </row-with-actions>
      </v-col>
    </v-row>
    <v-row
      v-if="getDisplayObjectClassificationByCategoryId(category._id).length > 0"
      v-for="(objectClassification, objectClassificationIndex) in getDisplayObjectClassificationByCategoryId(category._id)"
      class="border-b ps-3"
      :key="`classification-${objectClassification._id}`"
    >
      <v-col class="cursor-pointer"
             cols="12"
             v-if="authStore.hasServicePermission('object-classifications', ['get_all'])"
             @click="navigateToObjectClassificationDetail(objectClassification._id)">
        <row-with-actions>
          <template v-slot:left>
            {{ objectClassification.name }}
          </template>
          <template v-slot:right>
            <v-icon
              size="23"
              class="mb-1 ml-1 float-right"
              color="primary"
              v-if="authStore.hasServicePermission('object-classifications', ['remove_all'])"
              @click="objectClassificationStore.toggleDeleteDialog(objectClassification)" icon="mdi-delete"
            />
            <change-item-order
              v-if="authStore.hasServicePermission('object-classifications', ['patch_all'])"
              class="ml-3"
              :disabled-up="objectClassificationIndex === 0"
              :disabled-down="objectClassificationIndex === getDisplayObjectClassificationByCategoryId(category._id).length - 1"
              @move-up="moveClassificationUp(toRaw(objectClassification), toRaw(getDisplayObjectClassificationByCategoryId(category._id)))"
              @move-down="moveClassificationDown(toRaw(objectClassification), toRaw(getDisplayObjectClassificationByCategoryId(category._id)))"
            />
          </template>
        </row-with-actions>
      </v-col>
      <v-col v-else>
        <row-with-actions>
          <template v-slot:left>
            {{ objectClassification.name }}
          </template>
          <template v-slot:right>
            <v-icon
              size="23"
              class="mb-1 ml-1 float-right"
              color="primary"
              v-if="authStore.hasServicePermission('object-classifications', ['remove_all'])"
              @click="objectClassificationStore.toggleDeleteDialog(objectClassification)" icon="mdi-delete"
            />
            <change-item-order
              v-if="authStore.hasServicePermission('object-classifications', ['patch_all'])"
              class="ml-3"
              :disabled-up="objectClassificationIndex === 0"
              :disabled-down="objectClassificationIndex === category.children.length - 1"
            />
          </template>
        </row-with-actions>
      </v-col>
    </v-row>
    <v-row v-else class="border-b ps-3">
      <v-col cols="12">
        <em>There are no object classifications in this category.</em>
      </v-col>
    </v-row>
  </template>

  <delete-dialog :store="objectClassificationCategoryStore" :name="deleteItemObjectClassificationCategory?.name" @deleted="onDeletedCategory"></delete-dialog>
  <delete-dialog :store="objectClassificationStore" :name="deleteItemObjectClassification?.name" @deleted="onDeletedClassification"></delete-dialog>
</template>
