import { createRouter, createWebHistory} from "vue-router";

import ProjectIndexView from "@/views/projects/IndexView.vue";
import DetailView from "@/views/projects/DetailView.vue";
import ProjectBaseView from "@/views/projects/ProjectBaseView.vue";
import ProjectMembersView from "@/views/projects/MembersView.vue";
import ProjectTokensView from "@/views/projects/TokensView.vue";
import ProjectObjectsView from "@/views/projects/ObjectsView.vue";
import ProjectObjectClassificationsView from "@/views/projects/ObjectClassificationsView.vue";

import UsersIndexView from '@/views/users/IndexView.vue';

import ObjectClassificationsFormView from '@/views/object-classifications/FormView.vue'
import ObjectClassificationsDetailView from '@/views/object-classifications/DetailView.vue'

import SettingsBaseView from "@/views/settings/SettingsBaseView.vue";

import { authRoutes } from "@rhdhv/vue-authentication-middleware";
import { authorizationManagementRoutes} from "@rhdhv/vue-authorization-middleware";
import ProjectObjectClassificationDetail from "@/components/projects/ProjectObjectClassificationDetail.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: {
                name: 'auth-login'
            },
        },
        authRoutes,
        {
            path: '/projects',
            name: 'project-index',
            component: ProjectIndexView,
        },
        {
            path: '/projects/:id/',
            name: 'project-details',
            component: ProjectBaseView,
            children: [
                {
                    path: '',
                    name: 'project-info',
                    component: DetailView,
                },
                {
                    path: '/projects/:id/members',
                    name: 'project-members',
                    component: ProjectMembersView
                },
                {
                    path: '/projects/:id/tokens',
                    name: 'project-tokens',
                    component: ProjectTokensView
                },
                {
                    path: '/projects/:id/objects',
                    name: 'project-objects',
                    component: ProjectObjectsView
                },
                {
                    path: '/projects/:id/classifications',
                    name: 'project-object-classifications',
                    component: ProjectObjectClassificationsView
                },
                {
                    path: '/projects/:id/custom-object-classifications/:classificationId?',
                    name: 'project-custom-object-classifications',
                    component: ProjectObjectClassificationDetail,
                }
            ]
        },
        {
            path: '/settings',
            name: 'settings-index',
            component: SettingsBaseView,
            redirect: {
                name: 'settings-roles'
            },
            children: [
                authorizationManagementRoutes
            ]
        },
        {
            path: '/users',
            name: 'user-index',
            component: UsersIndexView
        },
        {
            path: '/object-classifications/form/:id?',
            name: 'object-classification-form',
            component: ObjectClassificationsFormView
        },
        {
            path: '/object-classifications/details/:id?',
            name: 'object-classification-details',
            component: ObjectClassificationsDetailView
        },
    ]
})

router.beforeEach(async (to, from) => {
    const authStore = useAuthStore()
    await authStore.getPromise()

    // TODO: This weirdness should not be necessary
    let userResponse = await authStore.reAuthenticate()
    if (userResponse) {
        authStore.$patch({
            user: userResponse.user
        })
    }

    if (to.name.startsWith('auth-')) {
        if (userResponse && userResponse.user) {
            return {name: 'project-index'}
        }
        return true
    }
    // check auth and apply login redirect
    if (!authStore.$state.user) {
        authStore.loginRedirect = to
        return {name: 'auth-login'}
    }
    return true
})

export default router
