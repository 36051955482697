import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import authentication from "@feathersjs/authentication-client";

const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL

const feathersApi = feathers()
const restClient = rest(apiBaseUrl).fetch(window.fetch.bind(window))
feathersApi.configure(restClient)
feathersApi.configure(authentication())
feathersApi.use('objects', restClient.service('objects'), {
		methods: ['find', 'get', 'create', 'patch', 'remove', 'import', 'export']
	})

export const api = feathersApi
export const client = restClient
