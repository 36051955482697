import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('project-members', api, snackbarStore);

export const useProjectMembersStore = defineStore('projectMembers', {
    state: () => ({
        ...baseStore.state,
    }),
    actions: {
        ...baseStore.actions,
    },
})
