<script setup>
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import ProjectObjectsTable from "@/components/projects/ProjectObjectsTable.vue";
import ProjectObjectDialog from "@/components/projects/ProjectObjectDialog.vue";
import ProjectObjectImportDialog from "@/components/projects/ProjectObjectImportDialog.vue";
import ProjectObjectExportDialog from "@/components/projects/ProjectObjectExportDialog.vue";

const {api} = useFeathers()
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const snackbarStore = useSnackbarStore()
const store = useObjectStore()
const projectStore = useProjectStore()
const objectClassificationCategoryStore = useObjectClassificationCategoryStore()
const objectClassificationStore = useObjectClassificationStore()
const projectObjectClassificationStore = useProjectObjectClassificationStore()
const objectStore = useObjectStore()
const {currentItem: currentProject} = storeToRefs(projectStore)

function onObjectSaved() {
  snackbarStore.setMessage('Object was saved successfully.', 'success', 5000)
}

function onObjectDeleted() {
  snackbarStore.setMessage('Object was removed successfully.', 'success', 5000)
}

onMounted(async () => {
  await objectClassificationCategoryStore.fetchItems()
  await objectClassificationStore.fetchItems()
})

watchEffect(async () => {
  if(currentProject.value._id){
    await projectObjectClassificationStore.fetchItems({
      project: currentProject.value._id
    })
  }
})
</script>

<template>
  <h1 class="font-weight-thin">
    Objects
    <v-btn
      v-if="authStore.hasServicePermission('objects', ['create_all', 'create_assigned'])"
      @click="store.toggleCreateUpdateDialog({project: currentProject._id})"
      class="ml-3 mb-1"
      append-icon="mdi-plus"
      color="primary"
      size="small"
      variant="outlined">
      Add
    </v-btn>
    <v-btn
        v-if="authStore.hasServicePermission('objects', ['create_all', 'create_assigned'])"
        @click="objectStore.toggleImportDialog(true)"
        class="ml-3 mb-1"
        append-icon="mdi-import"
        color="primary"
        size="small"
        variant="outlined">
      Import
    </v-btn>
    <v-btn
      v-if="authStore.hasServicePermission('objects', ['create_all', 'create_assigned'])"
      @click="objectStore.toggleExportDialog(true)"
      class="ml-3 mb-1"
      append-icon="mdi-export"
      color="primary"
      size="small"
      variant="outlined">
      Export
    </v-btn>
  </h1>
  <project-objects-table></project-objects-table>
  <delete-dialog :store="store" :name="'object'" @deleted="onObjectDeleted"></delete-dialog>
  <project-object-dialog @saved="onObjectSaved"></project-object-dialog>
  <project-object-import-dialog/>
  <project-object-export-dialog/>
</template>
