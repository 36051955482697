<script setup>
import ObjectClassificationMenu from "@/components/object-classifications/ObjectClassificationMenu.vue";
import ObjectClassificationDetail from "@/components/object-classifications/ObjectClassificationDetail.vue";

const route = useRoute()
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0 h-100">
      <v-col cols="3" class="ps-3 pe-3 h-100 border-e">
        <object-classification-menu></object-classification-menu>
      </v-col>
      <v-col cols="9">
        <object-classification-detail :object-classification-id="route.params.id"/>
      </v-col>
    </v-row>
  </v-container>
</template>
