<script setup>
import ProjectMenu from "@/components/projects/ProjectMenu.vue";

const { api } = useFeathers()
const store = useProjectStore()
const route = useRoute()

const {currentItem} = storeToRefs(store)


const snackbarStore = useSnackbarStore()

onMounted(async () => {
  await store.fetchItem(route.params.id)
})
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
  <v-row class="pa-0 ma-0 h-100">
      <v-col cols="3" class="ps-3 pe-3 h-100 border-e">
        <project-menu></project-menu>
      </v-col>
      <v-col cols="9">
        <router-view v-if="currentItem"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>