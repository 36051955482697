<script setup>
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";

const { api } = useFeathers()
import ProjectCard from "@/components/projects/ProjectCard.vue";
import ProjectDialog from "@/components/projects/ProjectDialog.vue";

const projectStore = useProjectStore()
const {items} = storeToRefs(projectStore)

const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()
const router = useRouter()


async function onProjectSaved(project){
  snackbarStore.setMessage('Project ' + project.name + ' was created successfully.', 'success', 5000)

  await router.push({
    name: 'project-info',
    params: {
      id: project._id
    }
  })
}

async function onProjectDeleted() {
    snackbarStore.setMessage('Project was deleted successfully', 'success', 5000)
}

onMounted(async () => {
  await projectStore.fetchItems()
})
</script>

<template>
  <v-container fluid class="h-min-135px">
      <v-row>
          <v-col cols="0" lg="1"></v-col>
          <v-col cols="12" lg="10">
                <v-row>
                    <v-col cols="10" class="ml-3">
                        <h1 class="font-weight-thin">
                            Projects
                            <v-btn
                                    v-if="authStore.hasServicePermission('projects', ['create_all'])"
                                    @click="projectStore.toggleCreateUpdateDialog()"
                                    class="ml-3 mb-1"
                                    append-icon="mdi-plus"
                                    color="primary"
                                    size="small"
                                    variant="outlined">
                                Add
                            </v-btn>
                        </h1>
                        <p>
                            To start using CDM, please select an existing project below or create a new project via the <v-chip class="ml-1 mr-1" append-icon="mdi-plus" label variant="outlined" size="x-small" color="primary">ADD</v-chip> button.
                        </p>
                    </v-col>
                </v-row>
                <v-row class="ma-0 mt-3">
                    <v-col cols="12" md="6" lg="3" v-for="item in items" :key="item._id">
                        <project-card :project="item"></project-card>
                    </v-col>
                </v-row>
          </v-col>
          <v-col cols="0" lg="1"></v-col>
      </v-row>
  </v-container>
  <project-dialog @saved="onProjectSaved"></project-dialog>
  <delete-dialog :store="projectStore" message="Are you sure you want to delete project <strong>{name}</strong>?" @deleted="onProjectDeleted"></delete-dialog>
</template>
