<script setup>
const authStore = useAuthStore()
const router = useRouter()
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="border-b ps-3">
      <v-col v-if="authStore.hasServicePermission('authmanagement-roles', ['find_all', 'find_assigned'])" class="cursor-pointer" cols="12" @click="router.push({ name: 'settings-roles' })">
        Roles
      </v-col>
    </v-row>
  </v-container>
</template>