<script setup>
</script>

<template>
  <div class="d-flex flex-row justify-space-between align-center">
    <slot>
      <div class="d-flex flex-row justify-space-between align-center">
        <slot name="left"></slot>
      </div>
      <div class="d-flex flex-row justify-space-between align-center">
        <slot name="right"></slot>
      </div>
    </slot>
  </div>
</template>
