<script setup>
import ObjectClassificationDetail from "@/components/object-classifications/ObjectClassificationDetail.vue";

const route = useRoute()
</script>

<template>
  <object-classification-detail
    v-if="route.params.classificationId"
    :object-classification-id="route.params.classificationId"/>
</template>
