<script setup>
import {hasValue} from "@/utils/validationUtils.js";
import ClassificationSelect from "@/components/projects/ClassificationSelect.vue";

const objectClassificationStore = useObjectClassificationStore()
const projectObjectClassificationStore = useProjectObjectClassificationStore()
const projectStore = useProjectStore()
const snackbarStore = useSnackbarStore()

const isValid = ref(true)
const formElement = ref(null)
const objectClassificationFieldFormData = ref({})
const { createUpdateDialog, selectedItems } = storeToRefs(projectObjectClassificationStore)
const { items: objectClassification, writeLoading } = storeToRefs(objectClassificationStore)
const { currentItem: currentProject } = storeToRefs(projectStore)

async function cloneObjectClassification() {
  const selectedObjectClassification = objectClassification.value.find(item => item._id === selectedItems.value)
  const requestData = {
    ...selectedObjectClassification,
    name: objectClassificationFieldFormData.value.name,
    order: selectedObjectClassification.order ?? 0,
    category: selectedObjectClassification.category._id,
    originalObjectClassification: selectedObjectClassification._id,
    project: currentProject.value._id,
  }
  delete requestData.fields
  try {
    const createdObjectClasification = await objectClassificationStore.create(requestData)
    await projectObjectClassificationStore.create({
      project: currentProject.value._id,
      classification: createdObjectClasification._id,
    })
    snackbarStore.setMessage('Object classification cloned successfully', 'success', 5000)
  } catch (e) {
    console.log(e)
    snackbarStore.setMessage('Could not clone the object classification', 'error', 5000)
  } finally {
    projectObjectClassificationStore.toggleCreateUpdateDialog(false)
  }
}

onUnmounted(() => {
  projectObjectClassificationStore.setSelectedItems([])
})
</script>

<template>
  <v-dialog
    v-model="createUpdateDialog"
    max-width="700px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span>Clone object classification</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form
          ref="formElement"
          @submit.prevent="cloneObjectClassification"
          v-model="isValid"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <classification-select
                  :items="objectClassificationStore.cloneableItems"
                  @update="projectObjectClassificationStore.setSelectedItems($event)"
                  :selected="selectedItems"
                  :multiple="false"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="objectClassificationFieldFormData.name"
                    type="text"
                    :rules="[hasValue]"
                    max-errors="999"
                    label="Name"
                    required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="cloneObjectClassification"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          Clone
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
