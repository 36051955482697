<script setup>
import ClassificationSelect from "@/components/projects/ClassificationSelect.vue";
import {useObjectStore} from "@/stores/objectStore.js";
import {getSafeFileName} from "@/utils/fileUtils.js";

const emit = defineEmits(['update:modelValue'])

const isValid = computed(() => {
  return !!objectImportFormData.value.objectClassification
})

const { api } = useFeathers()
const projectStore = useProjectStore()
const objectClassificationStore = useObjectClassificationStore()
const projectObjectClassificationStore = useProjectObjectClassificationStore()
const objectStore = useObjectStore()

const { items: projectClassificationItems } = storeToRefs(projectObjectClassificationStore)
const { items: objectClassifications } = storeToRefs(objectClassificationStore)
const { currentItem: currentProject } = storeToRefs(projectStore)
const { exportDialog, items: objects } = storeToRefs(objectStore)

const objectImportFormData = ref({
  objectClassification: null,
})


function setClassification(value) {
  objectImportFormData.value.objectClassification = value
}

const classificationObjects = computed(() => objectClassifications.value?.filter(item => {
  return projectClassificationItems.value?.findIndex(projectItem => projectItem.classification?._id === item._id) >= 0 &&
    objects.value.some(obj => obj.objectClassification._id === item._id);
}));

async function exportFile() {
  const validationResult = await formElement.value.validate()
  if (!validationResult.valid) {
    return false
  }
  try {
    const response = await fetch(import.meta.env.VITE_APP_API_BASE_URL + '/objects', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Service-Method': 'export',
        'Authorization': 'Bearer ' + (await api.authentication.getAccessToken())
      },
      body: JSON.stringify({
        'project': currentProject.value._id,
        'objectClassification': objectImportFormData.value.objectClassification,
      }),
    })
    const blob = await response.blob()
    const selectedObjectClassificaiton = classificationObjects.value.find(item => item._id === objectImportFormData.value.objectClassification)
    const fileName = getSafeFileName(`exported_${selectedObjectClassificaiton.name}`) + '.xlsx'
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e)
    snackbarStore.setMessage('Could not export the data', 'error', 5000)
  }
}

const formElement = ref(null)
watch(() => formElement.value, (newFormElementValue) => {
  if (newFormElementValue) {
    newFormElementValue.validate();
  }
})
</script>

<template>
  <v-dialog
    v-model="exportDialog"
    max-width="700px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span>Export objects</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="exportFile">
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <classification-select
                  :items="classificationObjects"
                  :multiple="false"
                  :selected="objectImportFormData.objectClassification"
                  @update="setClassification($event)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer/>
        <v-btn
          @click="exportFile"
          :disabled="!objectImportFormData.objectClassification"
          color="primary"
          variant="text"
        >
          Export to Excel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
