<script setup>
import ClassificationSelect from "@/components/projects/ClassificationSelect.vue";
import { formatDateTimeWithTimeZone } from "@/utils/dateUtils.js";

const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()
const objectTypeStore = useObjectTypeStore()
const objectStore = useObjectStore()
const objectClassificationStore = useObjectClassificationStore()
const objectClassificationCategoryStore = useObjectClassificationCategoryStore()
const projectClassificationStore = useProjectObjectClassificationStore()
const projectStore = useProjectStore()

const { 'items': projectClassificationItems} = storeToRefs(projectClassificationStore)
const { items, currentItem: currentObject } = storeToRefs(objectStore)
const {objectTypes} = storeToRefs(objectTypeStore)
const { 'items': objectClassificationCategories } = storeToRefs(objectClassificationCategoryStore)
const { 'items': objectClassifications } = storeToRefs(objectClassificationStore)
const { 'currentItem': currentProject, 'itemLoaded': currentProjectLoaded } = storeToRefs(projectStore)

const {api} = useFeathers()
const objectType = ref(null)
const startDate = ref(null)
const endDate = ref(null)
import { DateTime } from "luxon";


const headers = [
  {title: 'ID', align: 'start', sortable: false, key: 'objectClassification._id',},
  {title: 'Name', align: 'start', sortable: false, key: 'objectClassification.name',},
  {title: 'Category', align: 'start', sortable: false, key: 'objectClassification.category',},
  {title: 'Date added', align: 'start', key: 'createdAt'},
  {title: 'Added by', align: 'start', key: 'createdBy.fullName'},
  {title: 'Date modified', align: 'start', key: 'modifiedAt'},
  {title: 'Modified by', align: 'start', key: 'modifiedBy.fullName'},
  {title: 'Actions', align: 'end', key: 'actions', sortable: false},
]

const classifications = ref([])
watchEffect(async () => {
  if(currentProject.value._id){
    await objectStore.fetchItems({
      project: currentProject.value._id
    })
    await objectClassificationStore.fetchItems()
    await projectClassificationStore.fetchItems({
      project: currentProject.value._id
    })
    classifications.value = objectClassifications.value.filter(item => projectClassificationItems.value.map(i => i.classification && i.classification._id).includes(item._id))
  }
})

const zonedStartDate = computed(() => {
  if(startDate.value){
    return DateTime.fromISO(startDate.value, { zone: currentProject.value.timezone })
  }
  return null
})

const zonedEndDate = computed(() => {
  if(endDate.value){
    return DateTime.fromISO(endDate.value, { zone: currentProject.value.timezone })
  }
  return null
})

const filteredItems = computed(() => {
  if(!items.value){
    return []
  }
  return items.value.filter(item => {
    const zonedCreatedDate = DateTime.fromISO(item.createdAt).setZone(currentProject.value.timezone)
    if(zonedStartDate.value && zonedCreatedDate < zonedStartDate.value){
      return false
    }
    if(zonedEndDate.value && zonedCreatedDate > zonedEndDate.value){
      return false
    }
    return objectTypes.value.length === 0 || objectTypes.value.includes(item.objectClassification._id)
  })
})

function handleOnCopy(object) {
  const clonedObject = structuredClone(object)
  delete clonedObject._id
  objectStore.setParameter(currentObject.value, 'objectClassification', clonedObject.objectClassification)
  objectStore.setHasPrefilledData(true)
  objectStore.toggleCreateUpdateDialog(clonedObject)
}

onMounted(async () => {
    await objectClassificationCategoryStore.fetchItems()
})
</script>

<template>
  <div class="justify-space-between d-md-flex w-100">
    <classification-select
        class="mr-md-3 w-100"
        :items="classifications"
        :selected="objectTypes"
        @update:model-value="objectTypes=$event"
    ></classification-select>
    <v-text-field
        v-model="startDate"
        :label="'Created after (' + currentProject.timezone + ')'"
        class="mr-md-3 w-100"
        type="datetime-local"
    ></v-text-field>

    <v-text-field
        v-model="endDate"
        :label="'Created before (' + currentProject.timezone + ')'"
        class="mr-md-3 w-100"
        type="datetime-local"
    ></v-text-field>
  </div>
  <v-data-table
      items-per-page="10"
      :headers="headers"
      :items="filteredItems"
      item-value="email"
      density="compact"
  >
    <template v-slot:item.objectClassification.category="{ item }">
        {{ objectClassificationCategories.find(category => item.raw.objectClassification.category === category._id)?.name || 'Unknown' }}
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{ DateTime.fromISO(item.raw.createdAt).setZone(currentProject.timezone).toFormat(formatDateTimeWithTimeZone) }}
    </template>

    <template v-slot:item.modifiedAt="{ item }">
        {{ DateTime.fromISO(item.raw.modifiedAt).setZone(currentProject.timezone).toFormat(formatDateTimeWithTimeZone) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <div class="d-flex flex-row">
        <v-icon
            size="small"
            v-if="authStore.hasServicePermission('objects', ['create_all', 'create_assigned'])"
            @click="handleOnCopy(toRaw(item.raw))"
        >
          mdi-content-copy
        </v-icon>
        <v-icon
            class="ms-1"
            size="small"
            v-if="authStore.hasObjectPermission(item.raw, ['patch_all', 'patch_assigned'])"
            @click="objectStore.toggleCreateUpdateDialog(item.raw)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            class="ms-1"
            size="small"
            v-if="authStore.hasObjectPermission(item.raw, ['remove_all', 'remove_assigned'])"
            @click="objectStore.toggleDeleteDialog(item.raw)"
        >
          mdi-delete
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>
