<script setup>
import ProjectObjectClassificationsTable from "@/components/projects/ProjectObjectClassificationsTable.vue";
import ProjectObjectClassificationDialog from "@/components/projects/ProjectObjectClassificationDialog.vue";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";

const {api} = useFeathers()
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()

const projectStore = useProjectStore()
const objectClassificationCategoryStore = useObjectClassificationCategoryStore()
const objectClassificationStore = useObjectClassificationStore()
const projectObjectClassificationStore = useProjectObjectClassificationStore()
const store = useProjectObjectClassificationStore()

const {deleteItem, items} = storeToRefs(store)
const {'currentItem': currentProject} = storeToRefs(projectStore)

onMounted(async () => {
  await objectClassificationCategoryStore.fetchItems()
  await objectClassificationStore.fetchItems()
})

watchEffect(async () => {
  if(currentProject.value._id){
    await projectObjectClassificationStore.fetchItems({
      project: currentProject.value._id
    })
  }
})
</script>

<template>
  <h1 class="font-weight-thin">
    Object classifications
    <v-btn
      v-if="authStore.hasServicePermission('project-object-classifications', ['create_all', 'create_assigned'])"
      @click="store.toggleCreateUpdateDialog()"
      class="ml-3 mb-1"
      append-icon="mdi-plus"
      color="primary"
      size="small"
      variant="outlined">
      Add
    </v-btn>
  </h1>
  <project-object-classifications-table></project-object-classifications-table>
  <delete-dialog :store="store" :name="deleteItem?.classification?.name"></delete-dialog>
  <project-object-classification-dialog></project-object-classification-dialog>
</template>