import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('users', api, snackbarStore);

export const useUserStore = defineStore('users', {
    state: () => ({
        ...baseStore.state,
    }),
    actions: {
        ...baseStore.actions,
    },
})
