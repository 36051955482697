<script setup>
  import ObjectClassificationForm from "@/components/object-classifications/ObjectClassificationForm.vue";
  import ObjectClassificationMenu from "@/components/object-classifications/ObjectClassificationMenu.vue";

  const { api } = useFeathers()
  const route = useRoute()
  const router = useRouter()
  const objectClassificationStore = useObjectClassificationStore()
  const snackbarStore = useSnackbarStore()

  const editObject = ref(null)

  function onObjectSaved(object){
    snackbarStore.setMessage('Object classification ' + object.name + ' was saved successfully.', 'success', 5000)

    router.push({
      name: 'object-classification-details',
      params: {
        id: object._id
      }
    })
  }

  function onObjectCancelled(){
    if(editObject.value?._id){
      router.push({
        name: 'object-classification-details',
        params: {
          id: editObject.value._id
        }
      })
    } else {
      router.push({
        name: 'object-classification-details',
      })
    }
  }

  watch(() => route.params.id, async () => {
    if(route.params.id) {
      editObject.value = await objectClassificationStore.fetchItem(route.params.id)
    } else {
      editObject.value = null
    }
  })

  onMounted(async () => {
    if(route.params.id){
      editObject.value = await objectClassificationStore.fetchItem(route.params.id)
    }
  })
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0 h-100">
      <v-col cols="3" class="ps-3 pe-3 h-100 border-e">
        <object-classification-menu></object-classification-menu>
      </v-col>
      <v-col cols="9">
        <object-classification-form @cancel="onObjectCancelled"  @saved="onObjectSaved"></object-classification-form>
      </v-col>
    </v-row>
  </v-container>
</template>