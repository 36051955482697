<script setup>
import ObjectClassificationCategoryDialog
  from "@/components/object-classification-categories/ObjectClassificationCategoryDialog.vue";
import ObjectClassificationTree from "@/components/object-classifications/ObjectClassificationTree.vue";

const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()

const objectClassificationCategoryStore = useObjectClassificationCategoryStore()

function onSavedObjectClassificationCategory(){
  snackbarStore.setMessage('Object classification category saved successfully', 'success', 5000)
}
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col cols="12" class="border-b font-weight-bold" @click="objectClassificationCategoryStore.toggleCreateUpdateDialog()" v-if="authStore.hasServicePermission('object-classification-categories', ['create_all'])">
        <v-btn
          class="mt-2 mb-1"
          append-icon="mdi-plus"
          color="primary"
          size="small"
          variant="outlined">
          Add category
        </v-btn>
      </v-col>
    </v-row>
    <object-classification-tree></object-classification-tree>
  </v-container>

  <object-classification-category-dialog @saved="onSavedObjectClassificationCategory"></object-classification-category-dialog>
</template>