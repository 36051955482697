<script setup>
import { hasValue, isEmailAddress } from "../../utils/validationUtils.js";

const authorizationManagementRolesStore = inject('authorizationManagementRolesStore')

let emit = defineEmits();

const { api } = useFeathers()
const store = useUserStore()

const { currentItem, createUpdateDialog, writeLoading, validationErrors } = storeToRefs(store)
const { 'items': availableRoles } = storeToRefs(authorizationManagementRolesStore)

const userFormData = ref({})
const isValid = ref(true)
const formElement = ref(null)

async function saveUser () {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let item
  const formData = structuredClone(toRaw(userFormData.value))
  if(userFormData.value._id) {
    formData.role = formData.role?._id ? formData.role._id : formData.role
    item = await store.update(formData)
  } else {
    item = await store.create(formData)
  }

  if(item) {
    store.setCurrentItem(item)
    emit('saved', item)
    store.toggleCreateUpdateDialog(false)
  }
}

onMounted(async () => {
  await authorizationManagementRolesStore.fetchItems({
    authSource: 'users'
  })
})

watchEffect(async () => {
  if(currentItem.value._id){
    userFormData.value = structuredClone(toRaw(currentItem.value))
  } else {
    userFormData.value = {}
  }
})
</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="700px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="userFormData._id">Update user</span>
        <span v-else>Invite user</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="saveUser" v-model="isValid">
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="userFormData.firstName"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.firstName?.$errors"
                    label="First name"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="userFormData.lastName"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.lastName?.$errors"
                    label="Last name"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="userFormData.email"
                    :rules="[
                        v => hasValue(v),
                        v => isEmailAddress(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.email?.$errors"
                    label="Email address"
                    type="email"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-select
                    v-model="userFormData.role"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.role?.$errors"
                    :items="availableRoles"
                    item-title="name"
                    item-value="_id"
                    :menu-props="{ maxHeight: '400' }"
                    label="Role"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="saveUser"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          <template v-if="userFormData._id">Save</template>
          <template v-else>Invite</template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>