<script setup>
import ObjectClassificationTree from "@/components/object-classifications/ObjectClassificationTree.vue";
import ProjectObjectClassificationCloneDialog from "@/components/projects/ProjectObjectClassificationCloneDialog.vue";

const authStore = useAuthStore()
const projectStore = useProjectStore()
const { currentItem: currentProject } = storeToRefs(projectStore)
const router = useRouter()
const projectObjectClassificationStore = useProjectObjectClassificationStore()
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col
        cols="12"
        class="border-b font-weight-bold"
      >
        <div
          class="d-flex justify-space-between align-center mt-2 mb-1">
          <v-btn
            @click="router.push({ name: 'project-details', params: { id: currentProject._id }})"
            density="comfortable" icon="mdi-keyboard-backspace" size="small"
            :ripple="false" variant="outlined" color="primary" />
          <v-btn
            append-icon="mdi-content-copy"
            color="primary"
            size="small"
            variant="outlined"
            @click="projectObjectClassificationStore.toggleCreateUpdateDialog(true)"
          >
            Clone classification
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <object-classification-tree :project-id="currentProject._id" />
    <project-object-classification-clone-dialog />
  </v-container>
</template>
