<script setup>
import {DateTime} from "luxon";
import {hasValue, isNumber, isOptionalNumber} from "@/utils/validationUtils.js";
import {getNextOrder} from "@/utils/orderUtils.js";

const props = defineProps({
  objectClassification: {
    default: {}
  }
})
let emit = defineEmits([]);

const { api } = useFeathers()
const store = useObjectClassificationFieldStore()
const projectStore = useProjectStore()

const { currentItem, createUpdateDialog, writeLoading, validationErrors } = storeToRefs(store)

const objectClassificationFieldFormData = ref({})
const isValid = ref(true)
const formElement = ref(null)

async function save() {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let formData = structuredClone(toRaw(objectClassificationFieldFormData.value))
  if(formData.dataType === 'date-time') {
      if (formData.minDate) {
          formData.minDate = DateTime.fromISO(formData.minDate, {zone: 'utc'}).toISO()
      }
      if (formData.maxDate) {
          formData.maxDate = DateTime.fromISO(formData.maxDate, {zone: 'utc'}).toISO()
      }
  }
  if (formData.dataType === 'string') {
    for (const key of ['minLength', 'maxLength']) {
      if (formData[key] === '') {
        delete formData[key]
      }
    }
  }

  let item = null
  if(objectClassificationFieldFormData.value._id) {
    item = await store.update(formData)
  } else {
    const orderArray = props.objectClassification?.fields?.map(field => field.order) || []
    item = await store.create({
      ...formData,
      order: getNextOrder(orderArray),
    })
  }

  if(item) {
    store.setCurrentItem(item)
    emit('saved', item)
    store.toggleCreateUpdateDialog(false)
  }
}

watchEffect(async () => {
  if(currentItem.value._id){
    let item = structuredClone(toRaw(currentItem.value))
    if(item.dataType === 'date') {
      if (item.minDate) {
        item.minDate = DateTime.fromISO(item.minDate, { zone: 'utc' }).toFormat("y-LL-dd")
      }
      if (item.maxDate) {
        item.maxDate = DateTime.fromISO(item.maxDate, { zone: 'utc' }).toFormat("y-LL-dd")
      }
    } else if(item.dataType === 'date-time') {
      if (item.minDate) {
        item.minDate = DateTime.fromISO(item.minDate, { zone: 'utc' }).toISO({
          includeOffset: false
        })
      }
      if (item.maxDate) {
        item.maxDate = DateTime.fromISO(item.maxDate, { zone: 'utc' }).toISO({
          includeOffset: false
        })
      }
    }
    item.objectClassification = item.objectClassification._id

    objectClassificationFieldFormData.value = item
  } else {
    objectClassificationFieldFormData.value = {
      'objectClassification': props.objectClassification?._id,
      'required': true,
      'dataType': 'string',
      'choices': []
    }
  }
})

watchEffect(() => {
  if(['choice', 'multiplechoice'].includes(objectClassificationFieldFormData.value.dataType) && !objectClassificationFieldFormData.value.choices) {
    addChoice()
  }

  if(objectClassificationFieldFormData.value.dataType === 'integer') {
    if(objectClassificationFieldFormData.value.minValue && !Number.isInteger(objectClassificationFieldFormData.value.minValue)) {
      objectClassificationFieldFormData.value.minValue = parseInt(objectClassificationFieldFormData.value.minValue)
    }
    if(objectClassificationFieldFormData.value.maxValue && !Number.isInteger(objectClassificationFieldFormData.value.maxValue)) {
      objectClassificationFieldFormData.value.maxValue = parseInt(objectClassificationFieldFormData.value.maxValue)
    }
  }
})

function addChoice(){
  if(!objectClassificationFieldFormData.value.choices){
    objectClassificationFieldFormData.value.choices = []
  }
  objectClassificationFieldFormData.value.choices.push({
    value: '',
    displayValue: ''
  })
}

function deleteChoice(index){
  objectClassificationFieldFormData.value.choices.splice(index, 1)
}
</script>
<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="1200px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="currentItem?._id">{{ currentItem.name }}</span>
        <span v-else>New field for {{ objectClassification.name }}</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="saveProjectMember" v-model="isValid">
          <v-container >
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="objectClassificationFieldFormData.name"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.name?.$errors"
                    label="Name"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                    v-model="objectClassificationFieldFormData.dataType"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.dataType?.$errors"
                    :disabled="objectClassificationFieldFormData._id"
                    :items="['string', 'number','integer', 'choice', 'multiplechoice', 'date-time', 'date', 'boolean']"
                    :menu-props="{ maxHeight: '400' }"
                    label="Data type"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group
                        v-model="objectClassificationFieldFormData.required"
                        max-errors="999"
                        :error-messages="validationErrors.required?.$errors"
                        inline
                        label="Required field">
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-textarea
                    v-model="objectClassificationFieldFormData.description"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.description?.$errors"
                    label="Description"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                    v-model="objectClassificationFieldFormData.helpText"
                    max-errors="999"
                    :error-messages="validationErrors.helpText?.$errors"
                    label="Help text"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" v-if="['string'].includes(objectClassificationFieldFormData.dataType)">
                <v-text-field
                    type="number"
                    min="0"
                    v-model.number="objectClassificationFieldFormData.minLength"
                    :rules="[
                        isOptionalNumber,
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.minLength?.$errors"
                    label="Minimum length of input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="['string'].includes(objectClassificationFieldFormData.dataType)">
                <v-text-field
                    type="number"
                    min="0"
                    v-model.number="objectClassificationFieldFormData.maxLength"
                    :rules="[
                        isOptionalNumber,
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.maxLength?.$errors"
                    label="Maximum length of input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="['integer', 'number'].includes(objectClassificationFieldFormData.dataType)">
                <v-text-field
                    type="number"
                    min="0"
                    v-model.number="objectClassificationFieldFormData.minValue"
                    :rules="[
                        v => isNumber(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.minValue?.$errors"
                    label="Minimum value"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="['integer', 'number'].includes(objectClassificationFieldFormData.dataType)">
                <v-text-field
                    type="number"
                    min="0"
                    v-model.number="objectClassificationFieldFormData.maxValue"
                    :rules="[
                        v => isNumber(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.maxValue?.$errors"
                    label="Maximum value"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="['number'].includes(objectClassificationFieldFormData.dataType)">
                  <v-select
                          v-model.number="objectClassificationFieldFormData.precision"
                          max-errors="999"
                          :error-messages="validationErrors.precision?.$errors"
                          :items="[0.0001, 0.001, 0.01, 0.1, 1, 10, 100, 1000]"
                          :clearable="true"
                          :menu-props="{ maxHeight: '400' }"
                          label="Precision"
                  ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="['date', 'date-time'].includes(objectClassificationFieldFormData.dataType)">
                <v-text-field
                    :type="objectClassificationFieldFormData.dataType === 'date' ? 'date' : 'datetime-local'"
                    v-model="objectClassificationFieldFormData.minDate"
                    max-errors="999"
                    :error-messages="validationErrors.minDate?.$errors"
                    label="Minimum date (UTC)"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="['date', 'date-time'].includes(objectClassificationFieldFormData.dataType)">
                <v-text-field
                    :type="objectClassificationFieldFormData.dataType === 'date' ? 'date' : 'datetime-local'"
                    v-model="objectClassificationFieldFormData.maxDate"
                    max-errors="999"
                    :error-messages="validationErrors.maxDate?.$errors"
                    label="Maximum date (UTC)"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="['choice', 'multiplechoice'].includes(objectClassificationFieldFormData.dataType)">
              <v-col cols="12">
                <h4>
                  Choices
                  <v-btn
                      @click="addChoice()"
                      class="ml-3 mb-1"
                      append-icon="mdi-plus"
                      color="primary"
                      size="small"
                      variant="outlined">
                      Add
                  </v-btn>
                </h4>
              </v-col>
              <template v-if="objectClassificationFieldFormData.choices" v-for="(choice, index) of objectClassificationFieldFormData.choices" :key="index">
                <v-col cols="5">
                  <v-text-field
                      v-model="choice.value"
                      :rules="[
                          v => hasValue(v)
                      ]"
                      max-errors="999"
                      :error-messages="validationErrors.choices && validationErrors.choices[index]?.value?.$errors ? validationErrors.choices[index]?.value?.$errors : []"
                      label="Choice value"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="choice.displayValue"
                      :rules="[
                          v => hasValue(v)
                      ]"
                      max-errors="999"
                      :error-messages="validationErrors.choices && validationErrors.choices[index]?.displayValue?.$errors ? validationErrors.choices[index]?.displayValue?.$errors : []"
                      label="Choice display value"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-start justify-center">
                  <v-icon
                      @click="deleteChoice(key)"
                      size="small"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="save"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
