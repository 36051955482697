<script setup>
import {hasValue} from "../../utils/validationUtils.js";

const { api } = useFeathers()

let emit = defineEmits();

const projectTokenStore = useProjectTokenStore()

const { currentItem, createUpdateDialog, writeLoading, validationErrors } = storeToRefs(projectTokenStore)

const projectTokenFormData = ref({})
const isValid = ref(true)
const formElement = ref(null)

watch(currentItem, (newValue) => {
  projectTokenFormData.value = newValue ? structuredClone(toRaw(newValue)) : {};
}, { deep: true })

async function saveToken () {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let item
  if (currentItem.value?._id) {
    item = await projectTokenStore.update(projectTokenFormData.value)
  } else {
    item = await projectTokenStore.create(projectTokenFormData.value, ['tokenClearText'])
  }

  if(item) {
    emit('saved', item)
    projectTokenStore.toggleCreateUpdateDialog(false)
  }
}
</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="700px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="currentItem?._id">Update API token {{ currentItem.name }}</span>
        <span v-else>Add new API token</span>
      </v-card-title>

      <v-card-text class="pa-3">
        <v-form ref="formElement" @submit.prevent="saveProjectMember" v-model="isValid">
          <v-text-field
              v-model="projectTokenFormData.name"
              :rules="[
                  v => hasValue(v)
              ]"
              max-errors="999"
              :error-messages="validationErrors.name?.$errors"
              label="Name"
              required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="saveToken"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>