<script setup>
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import ProjectTokenDialog from "@/components/projects/ProjectTokenDialog.vue";
import ProjectTokensTable from "@/components/projects/ProjectTokensTable.vue";

const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL

const {api} = useFeathers()
const authStore = useAuthStore()

const store = useProjectTokenStore()
const projectStore = useProjectStore()
const snackbarStore = useSnackbarStore()

const {deleteItem, items} = storeToRefs(store)
const {currentItem: currentProject} = storeToRefs(projectStore)

const generatedToken = ref(null)
function onTokenSaved(token) {
  snackbarStore.setMessage('Token was saved successfully.', 'success', 5000)
  generatedToken.value = token
}

function onTokenDeleted() {
  snackbarStore.setMessage('Token was removed successfully.', 'success', 5000)
}

watchEffect(async () => {
  if(currentProject.value._id){
    await store.fetchItems({
      project: currentProject.value._id
    })
  }
})

function copyTokenToClipboard () {
  navigator.clipboard.writeText(generatedToken.value.tokenClearText);
  snackbarStore.setMessage('Token copied to clipboard successfully.', 'success')
}
</script>

<template>
  <h1 class="font-weight-thin">
    API Tokens
    <v-btn
      v-if="authStore.hasServicePermission('project-access-tokens', ['create_all', 'create_assigned'])"
      @click="store.toggleCreateUpdateDialog({project: currentProject._id})"
      class="ml-3 mb-1"
      append-icon="mdi-plus"
      color="primary"
      size="small"
      variant="outlined">
        Add
    </v-btn>
  </h1>
  <p class="mb-3" v-if="currentProject">
    API access tokens can be used to access CDM data using the API. To query for objects using an API token, use the following URL:<br />
    {{ apiBaseUrl }}/objects?project={{currentProject._id}}&expand=1&token=REPLACE_WITH_YOUR_API_ACCESS_TOKEN
  </p>
  <div v-if="generatedToken && generatedToken.tokenClearText">
    <v-alert
        type="success"
        title="Token created"
        class="mt-3 mb-3"
    >
      Your API token has been generated. Copy it to a safe place. <strong>The token is only shown once!</strong>

      <v-text-field style="width: 375px;" class="mt-3" readonly variant="solo" v-model="generatedToken.tokenClearText" ref="tokenToCopy">
        <template v-slot:append-inner>
          <v-icon @click="copyTokenToClipboard">mdi-content-copy</v-icon>
        </template>
      </v-text-field>

      <v-btn @click="generatedToken = null" variant="outlined" style="width: 375px;">
        I have copied the token to a safe place
      </v-btn>
    </v-alert>
  </div>

  <project-tokens-table></project-tokens-table>
  <delete-dialog :store="store" :name="'token'" @deleted="onTokenDeleted"></delete-dialog>
  <project-token-dialog @saved="onTokenSaved"></project-token-dialog>
</template>
