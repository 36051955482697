<script setup>
import ProjectCardMenu from "@/components/projects/ProjectCardMenu.vue";

const authStore = useAuthStore()
const router = useRouter()

const props = defineProps({
  project: {type: Object, required: true}
})

const isHovering = ref(false)
function navigateToProject (){
    if(authStore.hasObjectPermission(props.project, ['get_all', 'get_assigned'])){
      router.push({ name: 'project-info', params: { id: props.project._id }})
    }
}
</script>

<template>
  <v-card
    @click.stop="navigateToProject()"
    hover
    @mouseover="isHovering=true"
    @mouseout="isHovering=false"
    :elevation="isHovering ?  4 : 2"
    v-bind="props"
  >
    <v-row class="pt-2 pr-3">
        <v-col cols="10" class="pr-0 pb-3">
            <v-card-title class="elip">
                {{ project.name }}
            </v-card-title>
        </v-col>
        <v-col cols="2" class="me-0 pt-4 ps-1 pe-1 pb-0">
            <ProjectCardMenu :project="project"></ProjectCardMenu>
        </v-col>
    </v-row>

    <div class="pl-4">
      <v-chip class="mr-3" prepend-icon="mdi-map-marker" size="x-small" label>
          {{ project.country }}
      </v-chip>
      <span style="color: rgba(0,0,0,0.7); font-size: 14px; font-family: Roboto, sans-serif">
      {{ project.client }}
    </span>
    </div>

    <v-tooltip v-if="project.description" location="bottom" style="overflow-wrap: break-word" max-width="400px">
        <template v-slot:activator="{ props}">
            <v-card-text
                    class="elip pt-4 pb-2"
                    v-bind="props"
            >
                {{ project.description }}
            </v-card-text>
        </template>
        <span ref="element">{{ project.description }}</span>
    </v-tooltip>
    <v-card-text v-else class="elip pt-4 pb-2">-</v-card-text>
  </v-card>
</template>
