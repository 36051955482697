<script setup>
import {hasValue} from "@/utils/validationUtils.js";
import {getNextOrder} from "@/utils/orderUtils.js";

let emit = defineEmits([]);

const store = useObjectClassificationCategoryStore()

const { currentItem, createUpdateDialog, writeLoading, validationErrors, items } = storeToRefs(store)

const formData = ref({})
const isValid = ref(true)
const formElement = ref(null)

async function save() {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let item
  if(formData.value._id) {
    item = await store.update(formData.value)
  } else {
    item = await store.create({
      ...formData.value,
      order: getNextOrder(items.value.map(item => item.order)),
    })
  }

  if(item) {
    store.setCurrentItem(item)
    emit('saved', item)
    store.toggleCreateUpdateDialog(false)
  }
}

watchEffect(async () => {
  if(currentItem.value?._id){
    formData.value = structuredClone(toRaw(currentItem.value))
  } else {
    formData.value = {}
  }
})
</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="500px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="formData?._id">{{ formData.name }}</span>
        <span v-else>New category</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="saveProjectMember" v-model="isValid">
          <v-container >
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="formData.name"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.name?.$errors"
                    label="Name"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="formData.description"
                    :rules="[
                        v => hasValue(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.description?.$errors"
                    label="Description"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="save"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
