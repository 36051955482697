<script setup>
import {DateTime} from "luxon";
import ClassificationSelect from "@/components/projects/ClassificationSelect.vue";
import ProjectObjectDialogField from "@/components/projects/ProjectObjectDialogField.vue";

const isValid = ref(true)
const {api} = useFeathers()
const store = useObjectStore()
const projectStore = useProjectStore()
const objectClassificationStore = useObjectClassificationStore()
const projectObjectClassificationStore = useProjectObjectClassificationStore()

const { items: projectClassificationItems} = storeToRefs(projectObjectClassificationStore)
const { items: objectClassifications } = storeToRefs(objectClassificationStore)
const {currentItem: currentProject} = storeToRefs(projectStore)
const { createUpdateDialog, hasPrefilledData } = storeToRefs(store)
const {currentItem, writeLoading} = storeToRefs(store)

let emit = defineEmits(['update:modelValue']);

const objectFormData = ref({})

const objectClassification = computed(() => {
  return currentItem.value?.objectClassification ? objectClassificationStore.getItem(objectClassificationId.value) : null;
})

const dateFieldsToConvert = computed(() => {
  return objectClassification.value?.fields?.filter(field => ['date', 'date-time'].includes(field.dataType)).map(field => {
    return {
      '_id': field._id,
      'dataType': field.dataType
    }
  }) || []
})

const objectClassificationId = computed(() => {
  return currentItem.value.objectClassification?._id ? currentItem.value.objectClassification._id: currentItem.value.objectClassification
})

const classificationObjects = computed(() => objectClassifications.value?.filter(item =>
  projectClassificationItems.value?.findIndex(projectItem => projectItem.classification?._id === item._id) >= 0)
);

const objectClassificationFields = computed(() => {
  const fields = objectClassification.value?.fields || []
  return fields.sort((fieldA, fieldB) => fieldA.order - fieldB.order)
})

function setClassification(value) {
  store.setParameter(currentItem.value, 'objectClassification', value);
  objectFormData.value = {}
}

async function saveObject() {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }
  store.setParameter(currentItem.value, 'data', objectFormData.value)
  store.setParameter(currentItem.value, 'objectClassification', objectClassificationId.value) // Still required because patch and post differ
  store.setParameter(currentItem.value, 'project', currentProject.value._id)

  let objectData = structuredClone(toRaw(currentItem.value))
  delete objectData.output
  for(const dateFieldToConvert of dateFieldsToConvert.value){
      if(objectData.data[dateFieldToConvert._id]) {
          if(dateFieldToConvert.dataType === 'date-time') {
              objectData.data[dateFieldToConvert._id] = DateTime.fromISO(objectData.data[dateFieldToConvert._id], {zone: currentProject.value.timezone}).toISO()
          } else if(dateFieldToConvert.dataType === 'date'){
              objectData.data[dateFieldToConvert._id] = DateTime.fromISO(objectData.data[dateFieldToConvert._id], {zone: currentProject.value.timezone}).toFormat("y-LL-dd")
          }
      }
  }

  let item
  if (objectData._id) {
    item = await store.update(objectData)
  } else {
    item = await store.create(objectData)
  }

  if(item) {
      store.setCurrentItem(item)
      emit('saved', item)
      store.toggleCreateUpdateDialog(false)
  }
}

watchEffect((newValue) => {
  if(objectClassification.value && dateFieldsToConvert && currentItem.value._id) {
    const objectData = structuredClone(toRaw(currentItem.value)).data
    for(const dateFieldToConvert of dateFieldsToConvert.value){
      if(!objectData[dateFieldToConvert._id]){
          continue
      }

      if(dateFieldToConvert.dataType === 'date') {
        objectData[dateFieldToConvert._id] = DateTime.fromISO(objectData[dateFieldToConvert._id], {zone: currentProject.value.timezone}).toFormat("y-LL-dd")
      } if(dateFieldToConvert.dataType === 'date-time') {
        objectData[dateFieldToConvert._id] = DateTime.fromISO(objectData[dateFieldToConvert._id], {zone: currentProject.value.timezone}).toISO({
          includeOffset: false
        })
      }
    }
    objectFormData.value = objectData
  }
})

const formElement = ref(null)
watch(() => formElement.value, (newFormElementValue) => {
  if(newFormElementValue){
      newFormElementValue.validate();
  }
})

watch(createUpdateDialog, (newCreateUpdateDialogValue) => {
  if (newCreateUpdateDialogValue && hasPrefilledData.value) {
    objectFormData.value = structuredClone(toRaw(currentItem.value.data))
    store.setHasPrefilledData(false)
  }
})
</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="1500px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="currentItem?._id">Update object</span>
        <span v-else>Create object</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="saveObject" v-model="isValid">
          <v-container>
            <v-row v-if="!currentItem._id">
              <v-col
                  cols="12"
              >
                <classification-select
                    :items="classificationObjects"
                    :multiple="false"
                    :selected="objectClassificationId"
                    @update="setClassification($event)"
                ></classification-select>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <template v-for="field of objectClassificationFields">
                <v-col cols="12" md="6" lg="4" class="h-min-135px">
                  <ProjectObjectDialogField :field="field" v-model="objectFormData[field._id]"></ProjectObjectDialogField>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
            @click="saveObject"
            :disabled="isValid === false"
            :loading="writeLoading"
            color="primary"
            variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
