<script setup>
import ClassificationSelect from "@/components/projects/ClassificationSelect.vue";

const store = useProjectObjectClassificationStore()
const snackbarStore = useSnackbarStore()
const objectClassificationStore = useObjectClassificationStore()
const {createUpdateDialog, writeLoading, selectedItems} = storeToRefs(store)

const errorMessage = ref([])

watchEffect(() => {
    if(selectedItems.value && selectedItems.value.length > 0){
        errorMessage.value = []
    }
})

async function create() {
  errorMessage.value = []
  const items = store.defineObjects()
  if(items.length === 0){
      errorMessage.value = ['Please select at least one object classification']
      return
  }
  await store.bulkCreate(items)
  store.toggleCreateUpdateDialog()
  store.setSelectedItems()
}

</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="500px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="false">Update project object classification</span>
        <span v-else>Create project object classification</span>
      </v-card-title>

      <v-card-text class="pa-3">
        <classification-select
            :items="objectClassificationStore.selectableProjectObjectClassifications"
            :error-messages="errorMessage"
            @update="store.setSelectedItems($event)"
            :selected="selectedItems"
        ></classification-select>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
            @click="create()"
            :disabled="errorMessage.length !== 0"
            :loading="writeLoading"
            color="primary"
            variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>