import {getRandomArbitrary} from "@/utils/randomUtils.js";

export function getNextOrder(orders) {
  if (orders.length > 0) {
    return Math.max(...orders) + getRandomArbitrary(100, 200)
  }
  return 0
}


function findTwoSmallestNumbers(arr, num) {
  const result = [];
  for (const element of arr.sort((a, b) => a - b)) {
    if (element > num) {
      result.push(element);
      if (result.length === 2) {
        break;
      }
    }
  }
  return result;
}

function findTwoLargestNumbers(arr, num) {
  const result = [];
  for (const element of arr.sort((a, b) => b - a)) {
    if (element < num) {
      result.push(element);
      if (result.length === 2) {
        break;
      }
    }
  }
  return result;
}

export function getUpperOrder(orderArray, currentOrder) {
  const numbers = findTwoLargestNumbers(orderArray, currentOrder)
  if (numbers.length === 0) {
    return currentOrder - 100
  }
  if (numbers.length === 1) {
    return numbers[0] - 100
  }
  return numbers.reduce((acc, value) => acc + value, 0) / numbers.length
}

export function getLowerOrder(sortedOrderArray, currentOrder) {
  const numbers = findTwoSmallestNumbers(sortedOrderArray, currentOrder)
  if (numbers.length === 0) {
    return currentOrder + 100
  }
  if (numbers.length === 1) {
    return currentOrder + 100
  }
  return numbers.reduce((acc, value) => acc + value, 0) / numbers.length
}
