<script setup>
import ObjectClassificationMenu from "@/components/object-classifications/ObjectClassificationMenu.vue";
import ProjectObjectClassificationMenu from "@/components/projects/ProjectObjectClassificationMenu.vue";

const authStore = useAuthStore()
const router = useRouter()
const store = useProjectStore()
const { currentItem } = storeToRefs(store)

const currentRouteName = computed(() => router.currentRoute.value?.name || '')
</script>

<template>
  <v-container fluid class="pa-0 ma-0">
    <project-object-classification-menu
      v-if="currentRouteName === 'project-custom-object-classifications'"
      :show-custom-object-classifications-only="true"
    />
    <template v-else>
      <v-row class="border-b ps-3" v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned'])">
        <v-col class="cursor-pointer" cols="12" @click="router.push({ name: 'project-info', params: { id: currentItem._id }})">
          Details
        </v-col>
      </v-row>
      <v-row class="border-b ps-3" v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned']) && authStore.hasServicePermission('project-members', ['find_all', 'find_assigned'])">
        <v-col class="cursor-pointer" cols="12" @click="router.push({ name: 'project-members', params: { id: currentItem._id }})">
          Members
        </v-col>
      </v-row>
      <v-row class="border-b ps-3" v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned']) && authStore.hasServicePermission('project-access-tokens', ['find_all', 'find_assigned'])">
        <v-col class="cursor-pointer" cols="12" @click="router.push({ name: 'project-tokens', params: { id: currentItem._id }})">
          API tokens
        </v-col>
      </v-row>
      <v-row class="border-b ps-3" v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned']) && authStore.hasServicePermission('project-object-classifications', ['find_all', 'find_assigned'])">
        <v-col class="cursor-pointer" cols="12" @click="router.push({ name: 'project-object-classifications', params: { id: currentItem._id }})">
          Object classifications
        </v-col>
      </v-row>
      <v-row class="border-b ps-3" v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned']) && authStore.hasServicePermission('objects', ['find_all', 'find_assigned'])">
        <v-col class="cursor-pointer" cols="12" @click="router.push({ name: 'project-objects', params: { id: currentItem._id }})">
          Objects
        </v-col>
      </v-row>
      <v-row
        class="border-b ps-3"
        v-if="authStore.hasObjectPermission(currentItem, ['get_all', 'get_assigned']) && authStore.hasServicePermission('object-classifications', ['get_assigned','find_assigned','patch_assigned','create_assigned','remove_assigned'])">
        <v-col class="cursor-pointer" cols="12" @click="router.push({ name: 'project-custom-object-classifications', params: { id: currentItem._id }})">
          <p>Custom object classifications</p>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
