export const useUiStore = defineStore('ui', {
    state: () => ({
        sideBarState: false
    }),
    actions: {
        toggleSideBar(){
            this.sideBarState = !this.sideBarState
        },
        showSideBar(){
            this.sideBarState = true
        },
        hideSideBar(){
            this.sideBarState = false
        }
    },
})