<script setup>
const uiStore = useUiStore()
const router = useRouter()
const authStore = useAuthStore()

const { sideBarState } = storeToRefs(uiStore)

const group = ref(null)

watch(group, async () => {
  sideBarState.value = false
})
</script>
<template>
  <v-navigation-drawer
      v-model="sideBarState"
      location="left"
      floating
      temporary
      :width="400"
  >
    <v-list
        density="compact"
        nav
    >
      <template v-if="authStore.hasServicePermission('authmanagement-roles', ['find_all', 'find_assigned'])">
        <v-list-item @click="router.push({ name: 'settings-index' })" prepend-icon="mdi-cog" value="settings">
          Settings
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="authStore.hasServicePermission('users', ['find_all'])">
        <v-list-item @click="router.push({ name: 'user-index' })" prepend-icon="mdi-account" value="user-management">
            Users
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="authStore.hasServicePermission('projects', ['find_all', 'find_assigned'])">
      <v-list-item @click="router.push({ name: 'project-index' })" prepend-icon="mdi-briefcase" value="projects">
          Projects
      </v-list-item>
      <v-divider></v-divider>
      </template>
      <template v-if="authStore.hasServicePermission('object-classifications', ['find_all', 'find_assigned'])">
        <v-list-item @click="router.push({ name: 'object-classification-details' })" prepend-icon="mdi-draw" value="object-classifications">
          Object classifications
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>