<script setup>
import DocumentationButton from "./DocumentationButton.vue";

const router = useRouter()
const projectStore = useProjectStore()

const {currentItem: currentProject} = storeToRefs(projectStore)

const currentRoute = computed({
  get() {
    return router.currentRoute.value?.name;
  },
})
</script>

<template>
  <v-toolbar density="compact" color="secondary" class="w-100">
    <div class="ml-4">
      <v-toolbar-title style="font-size: 18px; font-weight: lighter; font-family: Bahnschrift" class="">
        Construction Data Management
      </v-toolbar-title>
    </div>
    <v-divider vertical class="ml-4"></v-divider>
    <v-breadcrumbs class="float-left">
      <v-breadcrumbs-item>
        <router-link to="/" class="text-white toolbar-text">Home</router-link>
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider></v-breadcrumbs-divider>
      <v-breadcrumbs-item
          v-if="['project-members', 'project-objects', 'project-tokens', 'project-object-classifications', 'project-info', 'project-index', 'project-custom-object-classifications'].includes(currentRoute)">
        <router-link :to="{ name: 'project-index' }" class="toolbar-text text-white">Projects</router-link>
      </v-breadcrumbs-item>
      <v-breadcrumbs-item v-if="['user-index'].includes(currentRoute)">
        <router-link :to="{ name: 'user-index' }" class="toolbar-text text-white">Users</router-link>
      </v-breadcrumbs-item>
      <v-breadcrumbs-item v-if="['settings-roles'].includes(currentRoute)">
        <router-link :to="{ name: 'settings-roles' }" class="toolbar-text text-white">Settings</router-link>
      </v-breadcrumbs-item>
      <v-breadcrumbs-item v-if="['object-classification-details', 'object-classification-form'].includes(currentRoute)">
        <router-link :to="{ name: 'object-classification-details' }" class="toolbar-text text-white">
          Object
          classifications
        </router-link>
      </v-breadcrumbs-item>
      <!--      TODO here the object classification selected needs to be included as well but therefore this needs to move to state first-->
      <template
          v-if="['project-members', 'project-objects', 'project-tokens', 'project-object-classifications', 'project-info', 'project-custom-object-classifications'].includes(currentRoute) && currentProject._id">
        <v-breadcrumbs-divider></v-breadcrumbs-divider>
        <v-breadcrumbs-item>
          <router-link class="text-white toolbar-text" :to="{ name: 'project-info', params: { id: currentProject._id } }">
            {{ currentProject.name }}
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider></v-breadcrumbs-divider>
        <v-breadcrumbs-item v-if="['project-members'].includes(currentRoute)">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-members', params: { id: currentProject._id } }">
            Members
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item v-if="['project-tokens'].includes(currentRoute)">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-tokens', params: { id: currentProject._id } }">
            API tokens
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item v-if="['project-info'].includes(currentRoute)">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-info', params: { id: currentProject._id } }">
            Details
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item v-if="['project-objects'].includes(currentRoute)">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-objects', params: { id: currentProject._id } }">
            Objects
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item v-if="['project-object-classifications'].includes(currentRoute)">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-objects', params: { id: currentProject._id } }">
            Object classifications
          </router-link>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item v-if="['project-custom-object-classifications'].includes(currentRoute)">
          <router-link class="text-white toolbar-text"
                       :to="{ name: 'project-custom-object-classifications', params: { id: currentProject._id } }">
            Custom object classifications
          </router-link>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <DocumentationButton></DocumentationButton>
  </v-toolbar>
</template>

<style scoped>

.toolbar-text {
  font-size: 15px
}


.v-breadcrumbs-divider {
  opacity: 0.5
}

</style>
