import BaseStore from '@rhdhv/vue-basestore'
import {pinia} from "@/modules/pinia.js";
const {api} = useFeathers()
const snackbarStore = useSnackbarStore(pinia)

const baseStore = new BaseStore('objects', api, snackbarStore);


export const useObjectStore = defineStore('object', {
    state: () => ({
        ...baseStore.state,
        hasPrefilledData: false,
        importDialog: false,
        exportDialog: false,
        importLoading: false,
    }),
    actions: {
        ...baseStore.actions,
        setHasPrefilledData(value) {
            this.hasPrefilledData = value
        },
        toggleImportDialog(value) {
            this.importDialog = value ?? !this.importDialog
        },
        toggleExportDialog(value) {
            this.exportDialog = value ?? !this.exportDialog
        },
        toggleImportLoading(value) {
            this.importLoading = value ?? !this.importLoading
        }
    },
    getters: {}
})
