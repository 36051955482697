export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        active: false,
        message: '',
        color: 'primary',
        closeColor: 'white',
        timeout: 5000
    }),
    actions: {
        setMessage (message, color = 'secondary', timeout = 5000, closeColor = 'white') {
            this.message = message
            this.color = color
            this.timeout = timeout
            this.active = true
            this.closeColor = closeColor
        }
    },
})