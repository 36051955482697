<script setup>
import AppHeader from "./components/app/Header.vue";
import LeftMenu from "@/components/app/LeftMenu.vue";
import Snackbar from "@/components/utils/Snackbar.vue";

const authStore = useAuthStore()
</script>

<template>
  <v-app>
    <AppHeader v-if="authStore.$state.user"></AppHeader>
    <v-main>
      <left-menu></left-menu>
      <router-view v-if="authStore.$state.isInitDone"/>

      <snackbar></snackbar>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "/src/scss/settings.scss";
</style>