<script setup>
import { hasValue, isNumber } from "../../utils/validationUtils.js";

let emit = defineEmits();

const { api } = useFeathers()
const store = useProjectStore()

const { currentItem, createUpdateDialog, writeLoading, validationErrors } = storeToRefs(store)
const isValid = ref(true)
const formElement = ref(null)

async function saveProject () {
  const validationResult = await formElement.value.validate()
  if(!validationResult.valid){
      return false
  }

  let item
  if (currentItem.value?._id) {
    item = await store.update(projectFormData.value)
  } else {
    item = await store.create(projectFormData.value)
  }
  if(item) {
    store.setCurrentItem(item)
    emit('saved', item)
    store.toggleCreateUpdateDialog(false)
  }
}


const projectFormData = ref({
  geoLocation: {}
})

watchEffect(async () => {
  if(currentItem.value._id){
    projectFormData.value = structuredClone(toRaw(currentItem.value))
  } else {
    projectFormData.value = {
      geoLocation: {}
    }
  }
})
</script>

<template>
  <v-dialog
      v-model="createUpdateDialog"
      max-width="1200px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span v-if="projectFormData._id">Update project</span>
        <span v-else>Create project</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="saveProject" v-model="isValid">
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.name"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    label="Name"
                    max-errors="999"
                    :error-messages="validationErrors.name?.$errors"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.workOrder"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.workOrder?.$errors"
                    label="Workorder"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="projectFormData.description"
                    max-errors="999"
                    :error-messages="validationErrors.description?.$errors"
                    label="Description"
                    required
                ></v-textarea>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.client"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.client?.$errors"
                    label="Client"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.country"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.country?.$errors"
                    label="Country"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.mainContractor"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.mainContractor?.$errors"
                    label="Main contractor"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model.number="projectFormData.startYear"
                    :rules="[
                        v => hasValue(v),
                        v => isNumber(v)
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.startYear?.$errors"
                    type="number"
                    label="Start year"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.geoLocation.x"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.geoLocation?.x?.$errors"
                    label="Geolocation (x)"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="projectFormData.geoLocation.y"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.geoLocation?.y?.$errors"
                    label="Geolocation (y)"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-select
                    v-model="projectFormData.contractType"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.contractType?.$errors"
                    :items="['Build', 'Design & Build', 'EPC/Turnkey Contract']"
                    label="Contract type"
                    required
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-select
                    v-model="projectFormData.timezone"
                    :rules="[
                        v => hasValue(v),
                    ]"
                    max-errors="999"
                    :error-messages="validationErrors.timezone?.$errors"
                    :items="['UTC-07:00', 'UTC-06:00', 'UTC-05:00', 'UTC-04:00', 'UTC-03:30', 'UTC-03:00', 'UTC-02:00', 'UTC-01:00', 'UTC', 'UTC+01:00', 'UTC+02:00', 'UTC+03:00', 'UTC+03:30', 'UTC+04:00', 'UTC+04:30', 'UTC+05:00', 'UTC+05:30', 'UTC+05:45', 'UTC+06:00', 'UTC+06:30', 'UTC+07:00', 'UTC+08:00', 'UTC+08:45', 'UTC+09:00', 'UTC+09:30', 'UTC+10:00', 'UTC+10:30', 'UTC+11:00', 'UTC+12:00', 'UTC+12:45', 'UTC+13:00', 'UTC+14:00']"
                    label="Timezone"
                    required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="saveProject"
          :disabled="isValid === false"
          :loading="writeLoading"
          color="primary"
          variant="text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>