<script setup>
import ObjectClassificationFields from "@/components/object-classifications/ObjectClassificationFields.vue";
import ObjectClassificationFieldDialog
  from "@/components/object-classification-fields/ObjectClassificationFieldDialog.vue";

const authStore = useAuthStore()
const router = useRouter()
const { api } = useFeathers()
const route = useRoute()
const snackbarStore = useSnackbarStore()
const objectClassificationStore = useObjectClassificationStore()
const objectClassificationCategoryStore = useObjectClassificationCategoryStore()
const objectClassificationFieldStore = useObjectClassificationFieldStore()

const props = defineProps({
  objectClassificationId: { type: String, required: false },
})

const objectTree = ref(null)
const objectDetails = ref(null)

watch(() => props.objectClassificationId, async () => {
  await setObjectClassification()
})

async function setObjectClassification() {
  if (props.objectClassificationId) {
    objectDetails.value = await objectClassificationStore.fetchItem(props.objectClassificationId)
  } else {
    objectDetails.value = null
  }
}

async function refreshObjectTree() {
  objectTree.value = await objectClassificationCategoryStore.fetchItems()
}

async function onObjectClassificationFieldSaved() {
  snackbarStore.setMessage('Object classification field was saved successfully', 'success', 5000)

  await setObjectClassification()
}

onMounted(async () => {
  await refreshObjectTree()
  await setObjectClassification()
})
</script>

<template>
  <template v-if="!objectDetails">
    <h1 class="font-weight-thin">Object classifications</h1>
    <p class="mt-3">
      Choose an object classifications from the left menu or
      <router-link :to="{ name: 'object-classification-form' }">create a new one.</router-link>
    </p>
  </template>
  <template v-else>
    <h1 class="font-weight-thin">
      {{ objectDetails.name }}
      <v-icon
        size="23" class="mb-1" color="primary"
        v-if="authStore.hasObjectPermission(objectDetails, ['patch_all'])"
        @click="router.push({ name: 'object-classification-form', params: { id: objectDetails._id }})"
        icon="mdi-pencil-circle"/>
    </h1>
    <p class="mt-3">
      {{ objectDetails.description }}
    </p>

    <h2 class="mt-3 mb-3">
      Fields
      <v-btn
        @click="objectClassificationFieldStore.toggleCreateUpdateDialog()"
        v-if="authStore.hasServicePermission('object-classification-fields', ['create_all'])"
        class="ml-3 mb-1"
        append-icon="mdi-plus"
        color="primary"
        size="small"
        variant="outlined">
        Add
      </v-btn>
    </h2>
    <template v-if="authStore.hasServicePermission('object-classification-fields', ['find_all'])">
      <p v-if="!props.objectClassificationId">
        Fields can be managed after creating the object.
      </p>
      <object-classification-fields
        v-else
        :objectClassification="objectDetails"/>
    </template>
  </template>
  <object-classification-field-dialog
    :object-classification="objectDetails"
    @saved="onObjectClassificationFieldSaved"/>
</template>
