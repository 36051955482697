<script setup>
const props = defineProps({
    project: {type: Object, required: true},
})

const store = useProjectStore()
</script>

<template>
    <v-menu
    >
        <template v-slot:activator="{ props }">
            <v-btn icon="mdi-dots-vertical"
                   size="small"
                   class="float-end"
                   flat
                   @mousedown.stop
                   v-bind="props">
            </v-btn>
        </template>

        <v-list density="compact">
            <v-list-item
                    @click="store.setCurrentItem(project); store.toggleCreateUpdateDialog(project)"
            >
                <v-list-item-title>
                    <v-row>
                        <v-col cols="4">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-col>
                        <v-col>
                            Edit
                        </v-col>
                    </v-row>
                </v-list-item-title>

            </v-list-item>

            <v-list-item
                    @click="store.setCurrentItem(project); store.toggleDeleteDialog(project)"
            >
                <v-list-item-title>
                    <v-row>
                        <v-col cols="4">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-col>
                        <v-col>
                            Delete
                        </v-col>
                    </v-row>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>