import get from "lodash/get";
import set from "lodash/set";

export const generateFormErrorsModel = (res) => {
    let errorObject = {}
    if(res.data) {
        res.data.forEach(error => {
            let errorPath = error.path
            if(error.message.includes('is missing the required field') && error.original?.params?.missingProperty){
                errorPath += "." + error.original.params.missingProperty
            }

            if (!error.message.includes('anyOf') && !error.message.includes('must be a null')) {
                let message = [error.message];
                let existingValue = get(errorObject, errorPath + ".$errors");
                if (!existingValue) {
                    set(errorObject, errorPath + ".$errors", message);
                } else {
                    set(errorObject, errorPath + ".$errors", [...message, ...existingValue]);
                }
            }
        });
    }

    return typeof errorObject['$'] !== 'undefined' ? errorObject['$'] : {}
}

export const hasValue = (input) => {
    if(input){
        return true
    }
    return "Field should have a value"
}

export const isEmailAddress = (input) => {
    // The following regex is taken from Ajv validation that is done on the backend
    if(input && /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(input)){
        return true
    }
    return "Field should be a valid email address"
}

export const isNumber = (input) => {
    if (typeof input === "number") {
        return true
    }
    if(input && !isNaN(input)){
        return true
    }
    return "Field should be a valid number"
}

export const isOptionalNumber = (input) => {
    if (typeof input === "undefined" || input === "") {
        return true
    }
    return isNumber(input)
}
