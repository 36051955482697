<script setup>
import UserDialog from "@/components/users/UserDialog.vue";
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";

const { api } = useFeathers()
const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()
const userStore = useUserStore()

const {items, deleteItem} = storeToRefs(userStore)

const authData = ref(null)

const search = ref(null)

const headers = [
  { title: 'First name', align: 'start', key: 'firstName' },
  { title: 'Last name', align: 'start', key: 'lastName' },
  { title: 'E-mail', align: 'start', key: 'email' },
  { title: 'Role', align: 'start', key: 'role.name' },
  { title: 'Verified', align: 'start', key: 'isVerified' },
  { title: 'Actions', key: 'actions', sortable: false },
]

function onSavedUser(user){
  snackbarStore.setMessage('User saved successfully', 'success', 5000)
}

function onDeletedUser(user){
  snackbarStore.setMessage('User deleted successfully', 'success', 5000)
}

onMounted(async () => {
  authData.value = await authStore.getPromise()
  await userStore.fetchItems()
})
</script>

<template>
  <v-container fluid class="h-100">
    <v-row>
      <v-col cols="0" lg="1"></v-col>
      <v-col cols="12" lg="10" class="ma-3">
        <h1 class="font-weight-thin">
          Users
          <v-btn
            @click="userStore.toggleCreateUpdateDialog()"
            v-if="authStore.hasServicePermission('users', ['create_all'])"
            class="ml-3 mb-1"
            append-icon="mdi-plus"
            color="primary"
            size="small"
            variant="outlined">
            Add
          </v-btn>
        </h1>

        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search users"
            label="Search users"
            single-line
            hide-details
            class="mb-5"
        ></v-text-field>

        <v-data-table
            items-per-page="10"
            :headers="headers"
            :items="items"
            item-value="email"
            density="compact"
            :search="search"
        >
          <template v-slot:item.firstName="{ item }">
            <span v-if="item.raw.firstName">{{ item.raw.firstName }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.lastName="{ item }">
            <span v-if="item.raw.lastName">{{ item.raw.lastName }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.isVerified="{ item }">
            <span v-if="item.raw.isVerified">Yes</span>
            <span v-else>No</span>
          </template>
          <template v-slot:item.role="{ item }">
            <span v-if="item.raw.role && item.raw.role === 'admin'">Administrator</span>
            <span v-else-if="item.raw.role && item.raw.role === 'schema_owner'">Schema owner</span>
            <span v-else-if="item.raw.role && item.raw.role === 'employee'">Employee</span>
            <span v-else>-</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
                size="small"
                v-if="authData && item.raw._id !== authData.user._id && authStore.hasServicePermission('users', ['remove_all'])"
                @click="userStore.toggleDeleteDialog(item.raw)"
            >
              mdi-delete
            </v-icon>

            <v-icon
                size="small"
                class="ml-2"
                v-if="authData && item.raw._id !== authData.user._id && authStore.hasServicePermission('users', ['patch_all'])"
                @click="userStore.toggleCreateUpdateDialog(item.raw)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="0" lg="1"></v-col>
    </v-row>
  </v-container>

  <user-dialog @saved="onSavedUser"></user-dialog>
  <delete-dialog :store="userStore" :name="deleteItem?.fullName" @deleted="onDeletedUser"></delete-dialog>
</template>