import {DateTime} from "luxon";

export function getStepSize(field){
    if(field.dataType === 'integer') {
        return 1
    } else if(field.dataType === 'number') {
        if(field.maxDecimals){
            return parseFloat(Math.pow(10, -field.maxDecimals)).toFixed(field.maxDecimals)
        }
        return 1
    }
    return undefined
}

export function getMinValue(field){
    return field.minValue
}

export function getMaxValue(field){
    return field.maxValue
}

export function getMinLength(field){
    if(field.minLength){
        return field.minLength
    }
    return undefined
}

export function getMaxLength(field){
    if(field.maxLength){
        return field.maxLength
    }
    return undefined
}

export function getMinDateValue(field, timezone) {
    if (field.minDate) {
        if(field.dataType === 'date'){
            return DateTime.fromISO(field.minDate, { zone: timezone }).toFormat("y-LL-dd")
        } else if(field.dataType === 'date-time'){
            return DateTime.fromISO(field.minDate, { zone: timezone }).toISO({
                includeOffset: false
            })
        }
    }
    return undefined
}

export function getMaxDateValue(field, timezone) {
    if (field.maxDate) {
        if (field.dataType === 'date') {
            return DateTime.fromISO(field.maxDate, { zone: timezone }).toFormat("y-LL-dd")
        } else if (field.dataType === 'date-time') {
            return DateTime.fromISO(field.maxDate, { zone: timezone }).toISO({
                includeOffset: false
            })
        }
    }
    return undefined
}

export function isMultipleOf(x, y) {
    return Math.round(x / y) / (1 / y) === x;
}
